import cx from 'classnames';
import { withFormik } from 'formik';
import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { compose } from 'recompose';

import Error from 'shared/components/Error';
import Loading from 'shared/components/Loading';
import { State } from 'shared/models';

import MultiSelect from '../MultiSelect';
import { SUBSCRIBER_TYPES } from '../constants';
import styles from './CreateBathForm.styles';

class CreateBatchForm extends Component {
  getNameValue = () => {
    const { state, utility, type } = this.props.values;
    const states = state ? state.map(state => state.abbr) : null;
    const statesLabel = states && states.length ? states.join(', ') + ' - ' : '';
    let utilitiesLabel = '';

    if (utility && utility.length === 1) {
      utilitiesLabel = `${utility.length} Utility - `;
    } else if (utility && utility.length > 1) {
      utilitiesLabel = `${utility.length} Utilities - `;
    }

    const typeLabel = type
      ? SUBSCRIBER_TYPES.filter(subscriberType => subscriberType.value === parseFloat(type)).map(
          subscriberType => subscriberType.label
        )
      : 'All';

    return `${statesLabel}${utilitiesLabel}${typeLabel}`;
  };

  componentDidUpdate(prevProps) {
    const { values, setFieldValue } = this.props;

    if (prevProps.values.state !== values.state) {
      setFieldValue('name', this.getNameValue());
    }

    if (prevProps.values.utility !== values.utility) {
      setFieldValue('name', this.getNameValue());
    }

    if (prevProps.values.type !== values.type) {
      setFieldValue('name', this.getNameValue());
    }
  }

  render() {
    const {
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      isExpanded,
      isFetching,
      isSubmitting,
      onCreateRemaining,
      onSelectBySubscriberClick,
      onToggle,
      remainingCustomersCount,
      serverError,
      setFieldValue,
      portfolios,
      utilities,
      values
    } = this.props;

    const stateValues = values.state ? values.state.map(state => parseFloat(state.value)) : null;
    const utilityOptions = Object.keys(utilities.byId)
      .map(id => utilities.byId[id])
      .filter(utility => (values.state && values.state.length ? stateValues.includes(utility.stateId) : true))
      .map(({ name, id }) => ({
        label: name,
        value: id
      }));

    const portfolioOptions = Object.keys(portfolios.byId)
      .map(id => portfolios.byId[id])
      .map(({ name, id }) => ({
        label: name,
        value: id
      }))
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

    const buttonText = portfolios.status !== 'fulfilled' ? 'Loading...' : 'All';

    const ServerError = () => {
      if (serverError && serverError.data !== 'There were no customers for the given filter criteria!') {
        return <Error className="my-2" error={serverError} />;
      }
      return null;
    };

    if (isFetching) return <Loading />;
    if (!isExpanded)
      return (
        <>
          <hr />
          <div className="px-4 mb-2">
            <Button block color="info" onClick={onToggle}>
              Create New Batch
            </Button>
          </div>

          <div className="px-4 mb-2">
            <Button block color="info" onClick={onSelectBySubscriberClick}>
              Select by Subscriber
            </Button>
          </div>
        </>
      );

    return (
      <div css={styles}>
        <hr />
        <div className="create-batch-form p-4 mb-2">
          <h5>
            <strong>Create a Batch</strong>
            {!!remainingCustomersCount && (
              <Button block className="my-2" color="light" onClick={onCreateRemaining}>
                Select Remaining Subscribers ({remainingCustomersCount})
              </Button>
            )}
          </h5>
          <Form autoComplete="nope" onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="state">State</Label>
              <MultiSelect
                disabled={isSubmitting}
                errors={errors}
                name="state"
                onBlur={handleBlur}
                onChange={value => {
                  setFieldValue('utility', []);
                  setFieldValue('state', value);
                }}
                options={Object.keys(State).map(key => ({
                  label: State[key].name,
                  value: key,
                  abbr: State[key].abbr
                }))}
                placeholder="State"
                setFieldValue={setFieldValue}
                values={values}
              />
            </FormGroup>
            <FormGroup>
              <Label for="utility">Utility</Label>
              <MultiSelect
                disabled={isSubmitting}
                errors={errors}
                name="utility"
                onBlur={handleBlur}
                onChange={value => {
                  setFieldValue('utility', value);
                }}
                options={utilityOptions}
                placeholder="Utility"
                setFieldValue={setFieldValue}
                values={values}
              />
            </FormGroup>
            <FormGroup>
              <Label for="type">Type</Label>
              <select
                className={cx('form-control', {
                  'is-invalid': errors['type']
                })}
                disabled={isSubmitting}
                errors={errors}
                name="type"
                onBlur={handleBlur}
                onChange={e => setFieldValue('type', e.target.value ? e.target.value : null)}
                placeholder="Type"
                values={values}
              >
                <option value="">All</option>
                {SUBSCRIBER_TYPES.map(type => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </FormGroup>
            <FormGroup>
              <Label for="portfolioId">Portfolio</Label>
              <select
                className={cx('form-control', {
                  'is-invalid': errors['portfolioId']
                })}
                disabled={isSubmitting}
                errors={errors}
                name="portfolioId"
                onBlur={handleBlur}
                onChange={e => setFieldValue('portfolioId', e.target.value ? e.target.value : null)}
                values={values}
              >
                <option value="">{buttonText}</option>
                {portfolioOptions.map(type => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
              <small>
                By selecting a portfolio, a customer will only be included if they exist in <strong>only</strong> the
                chosen portfolio.
              </small>
            </FormGroup>
            <FormGroup>
              <Label for="name">Batch Name</Label>
              <Input
                className="font-italic"
                disabled={isSubmitting}
                errors={errors}
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Name"
                value={values['name']}
              />
            </FormGroup>
            <Button block color="info" type="submit">
              Create Batch
            </Button>
            <ServerError />
            {errors.selectOne && <Error className="my-2">{errors.selectOne}</Error>}
          </Form>
          <Button block color="link" onClick={onToggle} size="sm">
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

export default compose(
  withFormik({
    mapPropsToValues: () => ({
      state: [],
      utility: [],
      type: '',
      name: '',
      portfolioId: null
    }),

    validate: values => {
      const errors = {};
      const { state, utility, type, portfolioId } = values;

      if (!state.length && !utility.length && type === '' && portfolioId === null) {
        errors.selectOne = 'Criteria must be selected to create a batch.';
      }

      return errors;
    },
    handleSubmit: (values, { props, resetForm, setSubmitting }) => {
      const { month: forMonth, year: forYear } = props;
      const { name, state, utility, type, portfolioId } = values;

      const data = {
        name,
        utilityIds: utility.map(u => u.value),
        stateIds: state ? state.map(s => s.value) : [],
        type: type ? [type] : [],
        forMonth,
        forYear,
        portfolioId
      };

      props.onCreateFromFilters(data);
      setSubmitting(false);
      resetForm();
      props.onToggle(false);
    }
  })
)(CreateBatchForm);
