import React, { createContext } from 'react';

export const InvoiceContext = createContext({
  month: null,
  year: null,
  billingId: null,
  billing: null
});

/*
  This is a HOC to simply map in the `month` and `year`
  from the React Router parameters.

  Usage:

  const ConnectedPreflightView = compose(
    withInvoiceContext,
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(View);

  This will give the `View` component access to the
  `props.month` and `props.year` automatically, as they're set on the top level context.
*/

export function withInvoiceContext(Component) {
  return function InvoiceContextualizedComponent(props) {
    return (
      <InvoiceContext.Consumer>
        {({ month, year, billingId, billing }) => (
          <Component {...props} billing={billing} billingId={billingId} month={month} year={year} />
        )}
      </InvoiceContext.Consumer>
    );
  };
}
