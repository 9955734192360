import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type FAIconProps = {
  icon: any;
};

export const FAIcon = (props: FAIconProps) => (
  <FontAwesomeIcon fixedWidth icon={props.icon} style={{ alignItems: 'center', fontSize: '1rem' }} />
);
