import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

import styles from './TableSearch.styles';

const TableSearch = ({ searchQuery, setSearchQuery, placeholderText }) => {
  return (
    <div css={styles}>
      <div className="search-wrapper">
        <div className="search-icon">
          <FontAwesomeIcon icon={faSearch} />
        </div>
        <DebounceInput
          value={searchQuery || ''}
          type="text"
          minLength={2}
          id="search"
          element={Input}
          debounceTimeout={300}
          onChange={e => setSearchQuery(e.target.value)}
          placeholder={placeholderText}
        />
      </div>
    </div>
  );
};

export default TableSearch;
