import PaymentType from 'shared/models/PaymentType';
import { selectAccountId } from 'shared/store/modules/accounts/selectors';
import {
  calculateCardFeeRequest,
  calculateCardFeeResponse,
  customerAddPaymentMethod,
  customerAddPaymentMethodError,
  customerAddPaymentMethodResponse,
  customerAutoPaymentsStatus,
  customerCancelAutoPay,
  customerEnrollInAutoPay,
  customerFetchError,
  customerFetchPaymentsRequest,
  customerFetchPaymentsResponse,
  customerFetchRequest,
  customerFetchResponse,
  customerGetBalance,
  customerGetBilling,
  customerSetPrimaryPaymentRequest,
  customerSetPrimaryPaymentResponse,
  customerUpdateRequest,
  customerUpdateResponse,
  customerVerifyBankAccount,
  deletePaymentError,
  deletePaymentSuccess,
  fetchFeeScheduleRequest,
  fetchFeeScheduleResponse,
  fetchCustomerEstimatedSavingsRequest,
  fetchCustomerEstimatedSavingsResponse,
  customerUpdateCardRequest,
  customerUpdateCardResponse,
  customerGetProvisionalSubscriptionRequest,
  customerGetProvisionalSubscriptionResponse
} from 'shared/store/modules/customers/actions';
import toast from 'shared/utilities/toast';

export const updateCustomer = (id, updates) => async (dispatch, _getState, { api }) => {
  dispatch(customerUpdateRequest());

  try {
    await api.subscriber.updateCustomer(id, updates);
    return dispatch(customerUpdateResponse());
  } catch (err) {
    return dispatch(customerUpdateResponse(err));
  }
};

export const fetchCustomer = customerId => async (dispatch, _getState, { api }) => {
  dispatch(customerFetchRequest());

  try {
    const { data: customer } = await api.subscriber.getCustomer(customerId);
    return dispatch(customerFetchResponse(customer));
  } catch (err) {
    return dispatch(customerFetchResponse(err));
  }
};

export const calculateCardFee = amount => async (dispatch, _getState, { api }) => {
  dispatch(calculateCardFeeRequest());
  try {
    const { data } = await api.payment.getCardFee(amount);
    return dispatch(calculateCardFeeResponse(data));
  } catch (err) {
    return dispatch(calculateCardFeeResponse(err));
  }
};

export const fetchFeeSchedule = () => async (dispatch, _getState, { api }) => {
  dispatch(fetchFeeScheduleRequest());
  try {
    const { data } = await api.payment.getFeeSchedule();
    return dispatch(fetchFeeScheduleResponse(data));
  } catch (err) {
    return dispatch(fetchFeeScheduleResponse(err));
  }
};

export const fetchCustomerPaymentSources = onlyUsable => async (dispatch, getState, { api }) => {
  dispatch(customerFetchPaymentsRequest());
  try {
    const customerId = selectAccountId(getState());
    const { data } = await api.subscriber.getCustomerPaymentSources(customerId, onlyUsable);
    dispatch(checkOptedIn());
    return dispatch(customerFetchPaymentsResponse(data));
  } catch (err) {
    return dispatch(customerFetchError(err));
  }
};

export const setPrimaryPaymentSource = paymentSourceId => async (dispatch, getState, { api }) => {
  dispatch(customerSetPrimaryPaymentRequest());
  try {
    const customerId = selectAccountId(getState());
    const { data } = await api.subscriber.setPrimaryPaymentSource(customerId, paymentSourceId);
    return dispatch(customerSetPrimaryPaymentResponse(data));
  } catch (err) {
    return dispatch(customerFetchError(err));
  }
};

export const updateCard = params => async (dispatch, getState, { api }) => {
  dispatch(customerUpdateCardRequest());
  const { paymentSourceId, values } = params;
  const customerId = selectAccountId(getState());
  try {
    const { data } = await api.subscriber.customerUpdateCard(customerId, paymentSourceId, values);
    toast.success('Card was successfully updated');
    return dispatch(customerUpdateCardResponse(data));
  } catch (err) {
    toast.error(err);
    return dispatch(customerUpdateCardResponse(err));
  }
};

export const addPaymentMethod = params => async (dispatch, getState, { api }) => {
  dispatch(customerAddPaymentMethod());

  // an attempt to keep things cleaner;
  // use the same action creators for both adding
  // bank accounts OR credit cards,
  // because they are (almost) identical
  const type = Object.keys(params)[0];
  const serviceAction = type === PaymentType.Card ? 'addCard' : 'addBankAccount';

  try {
    const customerId = selectAccountId(getState());
    const paymentTypeParams = params[type];

    const stripeArgs =
      type === PaymentType.Card
        ? {
            [type]: {
              cvc: paymentTypeParams.cvc,
              exp_month: paymentTypeParams.exp_month,
              exp_year: paymentTypeParams.exp_year,
              number: paymentTypeParams.number,
              address_line1: paymentTypeParams.address_line1,
              address_line2: paymentTypeParams.address_line2,
              address_city: paymentTypeParams.address_city,
              address_state: paymentTypeParams.address_state,
              address_zip: paymentTypeParams.address_zip,
              address_country: paymentTypeParams.address_country,
              name: paymentTypeParams.name
            }
          }
        : {
            [type]: {
              account_holder_name: paymentTypeParams.account_holder_name,
              account_holder_type: paymentTypeParams.account_holder_type,
              account_number: paymentTypeParams.account_number,
              country: paymentTypeParams.country,
              currency: paymentTypeParams.currency,
              routing_number: paymentTypeParams.routing_number
            }
          };

    const {
      data: { id: token }
    } = await api.stripe.fetchToken(stripeArgs);

    try {
      const data = await api.subscriber[`${serviceAction}`]({
        customerId,
        token,
        setPrimary: paymentTypeParams.setPrimary,
        optIntoAutoPayment: paymentTypeParams.optIntoAutoPay
      });
      dispatch(fetchCustomerPaymentSources(false));
      toast.success('Payment method successfully added');
      return dispatch(customerAddPaymentMethodResponse(data));
    } catch (err) {
      toast.error('Unable to add payment method');
      return dispatch(customerAddPaymentMethodError(err));
    }
  } catch (err) {
    return dispatch(customerFetchError(err));
  }
};

export const addPlaidPaymentMethod = (
  isVerified,
  token,
  isPrimary,
  optIntoAutoPayment,
  institutionName,
  maskedAccount,
  pendingToken
) => async (dispatch, getState, { api }) => {
  const customerId = selectAccountId(getState());
  let payload = {
    customerId,
    token,
    pendingProviderSourceId: pendingToken,
    optIntoAutoPayment,
    institutionName,
    maskedAccount
  };
  if (isPrimary && optIntoAutoPayment) {
    payload = {
      customerId,
      token,
      setPrimary: isPrimary,
      optIntoAutoPayment,
      institutionName,
      maskedAccount
    };
  } else if (isPrimary) {
    payload = {
      customerId,
      token,
      setPrimary: isPrimary,
      optIntoAutoPayment: false,
      institutionName,
      maskedAccount
    };
  }
  if (isVerified) {
    try {
      const data = await api.subscriber.addPlaidVerifiedBankAccount(payload);
      dispatch(fetchCustomerPaymentSources(false));
      if (data) {
        toast.success('Bank Account successfully added');
        return dispatch(customerAddPaymentMethodResponse(data));
      }
    } catch (err) {
      toast.error(err);
      return dispatch(customerAddPaymentMethodError(err));
    }
  } else {
    try {
      const data = await api.subscriber.addPlaidPendingBankAccount(payload);
      dispatch(fetchCustomerPaymentSources(false));
      if (data) {
        return dispatch(customerAddPaymentMethodResponse(data));
      }
    } catch (err) {
      toast.error(err);
      return dispatch(customerAddPaymentMethodError(err));
    }
  }
};

export const verifyBankAccount = values => async (dispatch, getState, { api }) => {
  dispatch(customerFetchPaymentsRequest());
  try {
    const customerId = selectAccountId(getState());
    const data = await api.subscriber.verifyBankAccount({
      ...values,
      customerId
    });

    if (data) {
      toast.success('Bank Account successfully verified');
      return dispatch(customerVerifyBankAccount(data));
    }
  } catch (err) {
    toast.error(err);
    return dispatch(customerFetchError(err));
  }
};

export const checkOptedIn = () => async (dispatch, getState, { api }) => {
  dispatch(customerFetchPaymentsRequest());
  try {
    const customerId = selectAccountId(getState());
    const {
      data: { result }
    } = await api.subscriber.autoPaymentsStatus(customerId);
    return dispatch(customerAutoPaymentsStatus(result));
  } catch (err) {
    return dispatch(customerFetchError(err));
  }
};

export const startAutoPay = () => async (dispatch, getState, { api }) => {
  try {
    const customerId = selectAccountId(getState());
    const { data } = await api.subscriber.optInAutoPayments(customerId);
    return dispatch(customerEnrollInAutoPay(data));
  } catch (err) {
    return dispatch(customerFetchError(err));
  }
};

export const stopAutoPay = () => async (dispatch, getState, { api }) => {
  try {
    const customerId = selectAccountId(getState());
    const { data } = await api.subscriber.optOutAutoPayments(customerId);
    return dispatch(customerCancelAutoPay(data));
  } catch (err) {
    return dispatch(customerFetchError(err));
  }
};

export const fetchProvisionalSubscriptionI = () => async (dispatch, getState, { api }) => {
  const customerId = selectAccountId(getState());
  dispatch(customerSetPrimaryPaymentRequest());
  try {
    const { data } = await api.subscriber.getProvisionalSubscriptionInfo(customerId);
    return dispatch(customerCancelAutoPay(data));
  } catch (err) {
    return dispatch(customerFetchError(err));
  }
};

export const fetchProvisionalSubscription = () => async (dispatch, getState, { api }) => {
  const customerId = selectAccountId(getState());
  dispatch(customerGetProvisionalSubscriptionRequest());

  try {
    const { data } = await api.subscriber.getProvisionalSubscriptionInfo(customerId);
    return dispatch(customerGetProvisionalSubscriptionResponse(data));
  } catch (err) {
    return dispatch(customerFetchError(err));
  }
};

export const deletePaymentSource = paymentSourceId => async (dispatch, getState, { api }) => {
  try {
    const customerId = selectAccountId(getState());
    if (paymentSourceId.indexOf('pending::') > -1) {
      await api.subscriber.deletePlaidPendingPaymentSource(customerId, paymentSourceId);
    } else {
      await api.subscriber.deletePaymentSource(customerId, paymentSourceId);
      toast.success('Bank Account successfully removed');
    }
    return dispatch(deletePaymentSuccess({ id: paymentSourceId }));
  } catch (err) {
    console.log(err, 'error');
    toast.error(err);
    return dispatch(deletePaymentError(err));
  }
};

export const getCustomerBilling = id => async (dispatch, getState, { api }) => {
  try {
    const customerId = selectAccountId(getState()) || id;
    const { data } = await api.subscriber.getCustomerBilling(customerId);
    return dispatch(customerGetBilling(data));
  } catch (err) {
    return dispatch(customerFetchError(err));
  }
};

export const getCustomerBalance = id => async (dispatch, getState, { api }) => {
  try {
    const customerId = selectAccountId(getState()) || id;
    const { data } = await api.subscriber.getCustomerBalance(customerId);
    return dispatch(customerGetBalance(data));
  } catch (err) {
    return dispatch(customerFetchError(err));
  }
};

export const fetchCustomerSavings = subscriptionId => async (dispatch, getState, { api }) => {
  dispatch(fetchCustomerEstimatedSavingsRequest());

  const customerId = selectAccountId(getState());

  try {
    const { data } = await api.subscriber.getCustomerEstimatedSavings(customerId, subscriptionId);
    return dispatch(fetchCustomerEstimatedSavingsResponse(data));
  } catch (err) {
    return dispatch(fetchCustomerEstimatedSavingsResponse(err));
  }
};
