import cx from 'classnames';
import { string } from 'prop-types';
import React from 'react';
import Select from 'react-select';

/*
Very quick and dirty state dropdown
*Requires withFormik() HOC wrapper*
*/
const MultiSelect = ({
  className,
  values,
  errors,
  onChange,
  onBlur,
  isSubmitting,
  placeholder,
  name,
  options,
  setFieldValue
}) => {
  return (
    <Select
      className={cx(className, {
        'is-invalid': errors[name]
      })}
      disabled={isSubmitting}
      isMulti
      name={name}
      onBlur={onBlur}
      onChange={onChange ? value => onChange(value) : value => setFieldValue(name, value)}
      options={options}
      placeholder={placeholder}
      value={values[name]}
    />
  );
};

MultiSelect.propTypes = {
  name: string.isRequired
};

export default MultiSelect;
