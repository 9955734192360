import { createSelector } from 'reselect';

import { getAllocationsById } from 'shared/store/modules/allocations/selectors';
import { getProjectsById } from 'shared/store/modules/projects/selectors';
import { getRateTypesById } from 'shared/store/modules/rateTypes/selectors';
import { getSchedulesById } from 'shared/store/modules/schedules/selectors';
import { getSubscriptionsById } from 'shared/store/modules/subscriptions/selectors';
import { getUtilitiesById } from 'shared/store/modules/utilities/selectors';

export function getPremisesById(state) {
  return state.admin.premises;
}

export function getPremise(state, props) {
  const premise = state.admin.premises.byId[props.match.params.premiseId];

  if (premise) {
    return premise;
  }

  return {};
}

export const getPremiseProjects = createSelector([getPremise, getProjectsById], (premise, projects) => {
  if (premise && premise.projects) {
    return premise.projects.map(id => projects.byId[id]);
  }

  return [];
});

export const getPremiseAllocations = createSelector([getPremise, getAllocationsById], (premise, allocations) => {
  if (premise && premise.allocations) {
    return premise.allocations.map(id => allocations.byId[id]);
  }

  return [];
});

export const getPremiseUtility = createSelector([getPremise, getUtilitiesById], (premise, utilities) => {
  if (premise && premise.utility) {
    return utilities.byId[premise.utility];
  }

  return {};
});

// TODO Duplicated this to prevent circular dependency
function getSubscribersById(state) {
  return state.admin.subscribers;
}

export const getPremiseSubscriber = createSelector([getPremise, getSubscribersById], (premise, subscribers) => {
  if (premise && premise.customer) {
    return subscribers.byId[premise.customer];
  }

  return {};
});

export const getPremiseSubscription = createSelector([getPremise, getSubscriptionsById], (premise, subscriptions) => {
  if (premise && premise.subscription) {
    return subscriptions.byId[premise.subscription];
  }

  return {};
});

export const getPremiseRateClass = createSelector(
  [getPremise, getUtilitiesById, getRateTypesById],
  (premise, utilities, rateTypes) => {
    let utility;
    let rateType;

    if (premise && premise.utility) {
      utility = utilities.byId[premise.utility];
    }

    if (utility && utility.rateType) {
      rateType = rateTypes.byId[utility.rateType];
    }

    if (rateType) {
      return rateType;
    }

    return {};
  }
);

export const getPremiseSchedules = createSelector([getPremise, getSchedulesById], (premise, schedules) => {
  if (premise && premise.schedules) {
    return premise.schedules.map(id => schedules.byId[id]).filter(schedule => !schedule.deleted);
  }

  return [];
});
