import { css } from '@emotion/react';
import sctheme from 'app/styles/sctheme';

const styles = sctheme => css`
  .create-batch-form {
    background: ${sctheme.grays.$100};
  }
`;

export default styles(sctheme);
