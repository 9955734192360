import cx from 'classnames';
import { node, string } from 'prop-types';
import React, { Component } from 'react';

export default class TruncatedText extends Component {
  static propTypes = {
    children: node.isRequired, // please note that the children must be a single element at this time
    className: string,
    element: string,
    title: string
  };

  static defaultProps = {
    element: 'div',
    children: ''
  };

  render() {
    const { children, className, element, ...restProps } = this.props;

    let childrenClass;
    let title;

    // verify the children's children are a string;
    if (typeof children === 'string') {
      title = children;
      childrenClass = className;
    } else {
      childrenClass = className || children.props.className;
      //  if so use as the `title` prop
      title =
        children && children.props && typeof children.props.children === 'string'
          ? children.props.children
          : this.props.title;
    }

    const elementClass = cx(childrenClass, 'text-truncate');

    if (!children) return null;
    // safeguard for some uses where Children might be undefined

    if (typeof children === 'string') {
      const Element = element;
      return (
        <Element className={elementClass} title={title} {...restProps}>
          {children}
        </Element>
      );
    } else {
      const childrenWithProps = React.Children.map(children, child =>
        React.cloneElement(child, {
          className: elementClass,
          title,
          ...restProps
        })
      );
      return <>{childrenWithProps}</>;
    }
  }
}
