import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { fetchYears } from 'shared/store/modules/invoices/effects';

/*
  small helper to redirect to the first available month/year
  (useful on the very first visit to the
  invoices view, e.g. hitting `/invoices`)
*/

class InvoiceRedirectTo extends Component {
  componentDidMount() {
    this.props.actions.fetchYears();
  }

  render() {
    const { billingDates } = this.props;
    let year;
    let month;
    if (billingDates) {
      year = Object.keys(billingDates)[0];
      month = billingDates[year][0];

      // redirect to current year and current month
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      if (billingDates[currentYear] && billingDates[currentYear].length) {
        year = currentYear;
        const dates = billingDates[currentYear];
        const currentMonthInDates = dates.findIndex(m => m === currentMonth);
        if (currentMonthInDates > -1) {
          month = dates[currentMonthInDates];
        } else {
          month = dates[dates.length - 1];
        }
      }
    }

    if (!month || !year) return null;

    return <Redirect to={`/admin/invoices/${month}/${year}`} />;
  }
}

const mapStateToProps = state => ({
  billingDates: state.invoices.billingDates
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchYears
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceRedirectTo);
