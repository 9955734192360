import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

import { getContractsById } from 'shared/store/modules/contracts/selectors';
import { getOffersById } from 'shared/store/modules/offers/selectors';
import { getRateTypesById } from 'shared/store/modules/rateTypes/selectors';

export function getUtilitiesById(state) {
  return state.admin.utilities;
}

export const getUtilities = createSelector([getUtilitiesById], utilities => {
  return orderBy(
    utilities.allIds.map(id => utilities.byId[id]),
    ['name']
  );
});

export const getUtility = createSelector(
  [getUtilitiesById, (_, props) => props.match.params.utilityId],
  (utilities, utilityId) => utilities.byId[utilityId]
);

export const getUtilityFromProps = createSelector(
  [getUtilitiesById, (_, props) => props.utilityId],
  (utilities, utilityId) => utilities.byId[utilityId]
);

export const getUtilityRateType = createSelector([getUtility, getRateTypesById], (utility, rateTypes) => {
  if (utility && utility.rateType) {
    return rateTypes.byId[utility.rateType];
  }
  return {};
});

export const getUtilityOffers = createSelector([getUtility, getOffersById], (utility, offers) => {
  if (utility && utility.offers) {
    return utility.offers.map(id => offers.byId[id]);
  }

  return [];
});

export const getUtilityContracts = createSelector(
  [(state, utilityId) => state.admin.utilities.byId[utilityId], getContractsById],
  (utility, contractsById) => {
    let contracts = [];

    if (utility && utility.contracts) {
      contracts = utility.contracts.map(id => contractsById[id]);
      contracts = contracts.filter(contract => !contract.deleted);
      contracts = orderBy(contracts, ['name'], ['asc']);
    }

    return contracts;
  }
);
