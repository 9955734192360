import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';

import AuthRoutes from 'app/Auth/Routes';
import PrivacyPolicy from 'app/PrivacyPolicy';
import Terms from 'app/Terms';
import asyncComponent from 'shared/utilities/asyncComponent';
import { Head } from 'shared/components/Head';
import { EnvironmentHeader } from 'shared/components/EnvironmentHeader';
import { Session } from 'shared/components/Session';
import { ShardProvider } from 'shared/components/ShardProvider';
import { LoginDispatch } from 'app/LoginDispatch';
import { history } from 'shared/store/reducers';

import { getAuth0Config } from 'shared/store/modules/provider/selectors';

import { useSelector } from 'react-redux';

const ResidentialRoutes = asyncComponent(() => import('app/Residential').then(module => module.default));
const SignupRoutes = asyncComponent(() => import('app/Signup').then(module => module.default));

const Auth0 = () => {
  const auth0Config = useSelector(getAuth0Config);

  const onLogin = (appState?: AppState) => {
    if (history.location.pathname !== '/signup/account-details') {
      history.push(appState?.returnTo || '/after-login');
    }
  };

  const auth0Options = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientId: auth0Config.clientId,
    cacheLocation: 'localstorage' as const,
    onRedirectCallback: onLogin,
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    authorizationParams: {
      organization: auth0Config.organizationId,
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE || ''
    }
  };

  return (
    <ConnectedRouter history={history}>
      <Auth0Provider {...auth0Options}>
        <Switch>
          {AuthRoutes.map((route, index) => (
            <Route component={route.component} key={index} path={route.path} />
          ))}
          <Route component={ResidentialRoutes} path="/residential" />
          <Route component={SignupRoutes} path="/signup" />
          <Route component={LoginDispatch} path="/after-login" />
          <Route component={PrivacyPolicy} path="/privacy" />
          <Route component={Terms} path="/terms" />
          <Route path="/" component={Session} />
        </Switch>
      </Auth0Provider>
    </ConnectedRouter>
  );
};

export function App() {
  return (
    <ShardProvider>
      <Head />
      <EnvironmentHeader />

      <Auth0 />
    </ShardProvider>
  );
}
