import cx from 'classnames';
import React from 'react';
/*
Very quick and dirty years dropdown,
iterating through the next {props.amount} years.

*Requires withFormik() HOC wrapper*
*/
const YearsDropdown = ({
  className,
  startYear,
  numYears,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  isFetching,
  isSubmitting,
  name,
  years
}) => {
  let options = [];

  if (!years.length) {
    for (let i = 0; i <= numYears; i++) {
      const value = startYear + i;
      options.push(
        <option key={value} value={value}>
          {value}
        </option>
      );
    }
  } else {
    options = years.map(y => (
      <option key={y} value={y}>
        {y}
      </option>
    ));
  }

  return (
    <select
      className={cx('form-control', className, {
        'is-invalid': touched[name] && errors[name]
      })}
      disabled={isFetching || isSubmitting}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder="Year"
      value={values[name]}
    >
      <option disabled value="">
        Select A Year
      </option>
      {options}
    </select>
  );
};

YearsDropdown.defaultProps = {
  startYear: new Date().getFullYear(),
  years: []
};

export default YearsDropdown;
