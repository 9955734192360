export const SUBSCRIBER_TYPES = [
  {
    label: 'Commercial',
    value: 0
  },
  {
    label: 'Residential',
    value: 1
  }
];
