import { createSelector } from 'reselect';

export function getSubscriptionsById(state) {
  return state.admin.subscriptions;
}

export const getSubscription = createSelector(
  [getSubscriptionsById, (_, props) => props.match.params.subscriptionId],
  (subscriptions, subscriptionId) => subscriptions.byId[subscriptionId]
);

export const selectSubscription = createSelector(
  [getSubscriptionsById, (_, subscriptionId) => subscriptionId],
  (subscriptions, subscriptionId) => subscriptions.byId[subscriptionId]
);