import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

import { getAllocationsById } from 'shared/store/modules/allocations/selectors';
import { getPortfoliosById } from 'shared/store/modules/portfolios/selectors';
import { getReadingsById } from 'shared/store/modules/readings/selectors';
import { getUtilitiesById } from 'shared/store/modules/utilities/selectors';

export function getProjectsById(state) {
  return state.admin.projects;
}

export function selectProjectsStatus(state) {
  return state.admin.projects.status;
}

export const getProjectById = createSelector(
  [getProjectsById, (_state, props) => props.id],
  (projects, id) => projects.byId[id]
);

export function getProject(state, props) {
  const project = state.admin.projects.byId[props.match.params.projectId];
  return project || {};
}

export const getProjects = createSelector([getProjectsById], projects => {
  return orderBy(
    projects.allIds.map(id => projects.byId[id]),
    ['name']
  );
});

export const getProjectAllocations = createSelector([getProject, getAllocationsById], (project, allocations) => {
  if (project && project.allocations) {
    return project.allocations.map(id => allocations.byId[id]);
  }

  return [];
});

export const getProjectReadings = createSelector([getProject, getReadingsById], (project, readings) => {
  if (project && project.monthlyReadings) {
    return orderBy(
      project.monthlyReadings.filter(reading => reading !== undefined).map(id => readings.byId[id]),
      ['year', 'month'],
      ['asc', 'desc']
    );
  }

  return [];
});

export const getProjectUtility = createSelector([getProject, getUtilitiesById], (project, utilities) => {
  if (project && project.utility) {
    return utilities.byId[project.utility];
  }

  return {};
});

export const getProjectPortfolio = createSelector([getProject, getPortfoliosById], (project, portfolios) => {
  if (project && project.portfolio) {
    return portfolios.byId[project.portfolio];
  }

  return {};
});

export function getIsUploading(state) {
  return state.admin.projects.byId.isUploading;
}
