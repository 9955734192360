import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

import { getActivitiesById } from 'shared/store/modules/activities/selectors';
import { getDeferredChargesById } from 'shared/store/modules/deferredCharges/selectors';
import { getDocumentsById } from 'shared/store/modules/documents/selectors';
import { getPremisesById } from 'shared/store/modules/premises/selectors';
import { getProjectsById } from 'shared/store/modules/projects/selectors';
import { getSubscriptionsById } from 'shared/store/modules/subscriptions/selectors';
import { getUsersById } from 'shared/store/modules/users/selectors';

export function getSubscribersById(state) {
  return state.admin.subscribers;
}

export function selectSubscribersStatus(state) {
  return state.admin.subscribers.status;
}

export const getSubscriberById = createSelector(
  [getSubscribersById, (_state, props) => props.id],
  (subscribers, id) => subscribers.byId[id]
);

export const getSubscriberByRouteId = createSelector(
  [getSubscribersById, (_state, props) => props.match.params.subscriberId],
  (subscribers, id) => subscribers.byId[id]
);

export const getSubscriber = createSelector(
  [getSubscribersById, (_state, props) => props.match.params.subscriberId],
  (subscribers, subscriberId) => subscribers.byId[subscriberId]
);

export const getSubscribers = createSelector([getSubscribersById], subscribers => {
  return orderBy(
    subscribers.allIds.map(id => subscribers.byId[id]),
    ['company']
  );
});

export const getSubscriberActivities = createSelector([getSubscriber, getActivitiesById], (subscriber, activities) => {
  if (subscriber && subscriber.activities) {
    return subscriber.activities.map(id => activities.byId[id]).filter(n => n);
  }

  return [];
});

export const getSubscriberBilling = createSelector([getSubscriber], subscriber => {
  if (subscriber && subscriber.billingDashboard) {
    return subscriber.billingDashboard;
  }

  return {};
});

export const getSubscriberDocuments = createSelector([getSubscriber, getDocumentsById], (subscriber, documents) => {
  if (subscriber && subscriber.documents) {
    return orderBy(
      subscriber.documents.map(id => documents.byId[id]).filter(n => n),
      ['createdAt'],
      ['desc']
    );
  }

  return [];
});

export const getSubscriberDeferredCharges = createSelector(
  [getSubscriber, getDeferredChargesById],
  (subscriber, deferredCharges) => {
    if (subscriber && deferredCharges) {
      const charges = Object.keys(deferredCharges.byId)
        .map(key => deferredCharges.byId[key])
        .filter(charge => charge)
        .filter(charge => !charge.deleted)
        .filter(charge => charge.customerId === subscriber.id);

      return orderBy(charges, ['createdAt'], ['desc']);
    }

    return [];
  }
);

export const getSubscriberPremises = createSelector([getSubscriber, getPremisesById], (subscriber, premises) => {
  if (subscriber && subscriber.premises) {
    return subscriber.premises.map(id => premises.byId[id]).filter(n => n);
  }

  return [];
});

export const getSubscriberProjects = createSelector([getSubscriber, getProjectsById], (subscriber, projects) => {
  if (subscriber && subscriber.projects) {
    return subscriber.projects.map(id => projects.byId[id]).filter(n => n);
  }

  return [];
});

export const getSubscriberSubscriptions = createSelector(
  [getSubscriber, getSubscriptionsById],
  (subscriber, subscriptions) => {
    if (subscriber && subscriber.subscriptions) {
      return subscriber.subscriptions.map(id => subscriptions.byId[id]).filter(n => n);
    }

    return [];
  }
);

export const getSubscriberUsers = createSelector([getSubscriber, getUsersById], (subscriber, users) => {
  if (subscriber && subscriber.users) {
    return subscriber.users.map(id => users.byId[id]).filter(n => n);
  }

  return [];
});
