// USE FOR CLIENT SIDE TABLE PAGINATION (i.e. all data is served at once)
import React, { useCallback, useState, useEffect } from 'react';
import { Pagination, PaginationItem, PaginationLink, Container, Row, Col } from 'reactstrap';
import Select from 'react-select';

const TablePaginationAuto = ({ canNextPage, canPreviousPage, pageCount, gotoPage, setPageSize, pageSize, data }) => {
  const [visiblePages, setVisiblePages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const filterPages = (visiblePages, pageCount) => {
    return visiblePages.filter(currentPage => currentPage <= pageCount);
  };

  const getVisiblePages = useCallback((currentPage, pageCount) => {
    const pageToUse = currentPage + 1;
    if (pageCount < 7) {
      return setVisiblePages(filterPages([1, 2, 3, 4, 5, 6], pageCount));
    } else {
      if (pageToUse % 5 >= 0 && pageToUse > 4 && pageToUse + 2 < pageCount) {
        return setVisiblePages([1, pageToUse - 1, pageToUse, pageToUse + 1, pageCount]);
      } else if (pageToUse % 5 >= 0 && pageToUse > 4 && pageToUse + 2 >= pageCount) {
        return setVisiblePages([1, pageCount - 3, pageCount - 2, pageCount - 1, pageCount]);
      } else {
        return setVisiblePages([1, 2, 3, 4, 5, pageCount]);
      }
    }
  }, []);

  useEffect(
    currentPage => {
      getVisiblePages(currentPage, pageCount);
    },
    [getVisiblePages, pageCount]
  );

  const changePage = page => {
    getVisiblePages(page, pageCount);
    gotoPage(page);
  };

  const handlePageChange = page => {
    changePage(page);
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    changePage(currentPage + 1);
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    changePage(currentPage - 1);
    setCurrentPage(currentPage - 1);
  };

  const customStyles = {
    menu: provided => ({
      ...provided,
      width: 110,
      borderBottom: '1px dotted #555',
      padding: 5
    }),
    container: _ => ({
      width: 75
    })
  };

  let dataStart = currentPage === 0 ? currentPage + 1 : currentPage * pageSize + 1;
  dataStart = dataStart > data.length ? data.length : dataStart;

  let dataEnd = currentPage === 0 && data.length >= pageSize ? pageSize : (currentPage + 1) * pageSize;
  dataEnd = dataEnd > data.length ? data.length : dataEnd;

  return (
    <div className="pagination-container">
      <Container>
        <Row>
          <Col className="col-sm-12 col-lg-3 pt-4 text-center">
            <span style={{ color: '#999' }}>
              {dataStart} - {dataEnd} of {data.length} items
            </span>
          </Col>
          <Col className="col-sm-6 col-lg-6">
            <Pagination className="mt-3" listClassName="justify-content-center">
              <PaginationItem disabled={!canPreviousPage}>
                <PaginationLink onClick={() => handlePreviousPage()}>{'Previous'}</PaginationLink>
              </PaginationItem>
              {visiblePages.map((page, index, array) => {
                let truePage;
                if (pageCount <= 6) {
                  truePage = index;
                } else {
                  truePage = page - 1;
                }
                return array[index - 1] + 2 <= page ? (
                  <React.Fragment key={page}>
                    <PaginationItem disabled>
                      <PaginationLink>&hellip;</PaginationLink>
                    </PaginationItem>
                    <PaginationItem active={currentPage === truePage}>
                      <PaginationLink onClick={() => handlePageChange(truePage)}>{page}</PaginationLink>
                    </PaginationItem>
                  </React.Fragment>
                ) : (
                  <PaginationItem active={currentPage === truePage} key={page}>
                    <PaginationLink onClick={() => handlePageChange(truePage)}>{page}</PaginationLink>
                  </PaginationItem>
                );
              })}
              <PaginationItem disabled={!canNextPage}>
                <PaginationLink onClick={() => handleNextPage()}>{'Next'}</PaginationLink>
              </PaginationItem>
            </Pagination>
          </Col>
          <Col className="col-sm-6 col-lg-3 pt-3">
            <div className="table-size-selector">
              <Select
                getOptionLabel={pageSize => pageSize + ' items'}
                getOptionValue={pageSize => pageSize}
                name="tableSize"
                onChange={pageSize => setPageSize(pageSize)}
                options={[5, 10, 20, 30, 40, 50]}
                value={pageSize}
                placeholder={pageSize ? `${pageSize}` : 'Page Size...'}
                styles={customStyles}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TablePaginationAuto;
