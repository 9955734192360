import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Card, CardDeck, CardBody } from 'reactstrap';
import { compose } from 'recompose';

import { withInvoiceContext } from 'app/Portal/Admin/Invoices/InvoicesContext';
import Icon from 'shared/components/Icon';

import CreateBatchForm from './CreateBatchForm/CreateBatchForm';
import PeriodForm from './PeriodForm';
import SelectBatchForm from './SelectBatchForm';

class InvoicesNavigation extends Component {
  state = {
    isCreatingBatch: false
  };

  toggleCreateBatch = () => {
    this.setState(prevState => ({
      isCreatingBatch: !prevState.isCreatingBatch
    }));
  };

  render() {
    const {
      billing,
      billingDates,
      billingId,
      recentlyDeleted,
      handleDownloadExport,
      history,
      isInvoicingBusy,
      isFetching,
      month,
      onCreateFromFilters,
      onFinalize,
      onGenerate,
      onPreflight,
      onSelectBySubscriber,
      onToggleCreateRemainingModal,
      onToggleDeletingModal,
      remainingCustomersCount,
      serverError,
      utilities,
      portfolios,
      year,
      data,
      billingDeleteAllCustomers,
      billingDeleteAllPreflight,
    } = this.props;

    const { isCreatingBatch } = this.state;

    const { canGenerate, canAbort, canFinalize } = data;

    const availableYears = billingDates ? Object.keys(billingDates) : [];
    const availableMonths = billingDates && billingDates[year] ? billingDates[year] : [];

    return (
      <>
        <CardDeck className="mb-2">
          <Card>
            <CardBody>
              <header className="p-4">
                <h5>
                  <strong>Period</strong>
                </h5>
                <PeriodForm
                  availableMonths={availableMonths}
                  availableYears={availableYears}
                  billingDates={billingDates}
                  isFetching={isFetching}
                  month={month}
                  year={year}
                />
              </header>
              {billing && (
                <SelectBatchForm
                  billing={billing}
                  onSubmit={billingId => history.push(`/admin/invoices/${month}/${year}/${billingId}`)}
                  selectedBilling={billingId}
                  billingDeleteAllPreflight={billingDeleteAllPreflight}
                  billingDeleteAllCustomers={billingDeleteAllCustomers}
                  recentlyDeleted={recentlyDeleted}
                />
              )}
              <CreateBatchForm
                isExpanded={isCreatingBatch}
                month={month}
                onCreateFromFilters={onCreateFromFilters}
                onCreateRemaining={onToggleCreateRemainingModal}
                onSelectBySubscriberClick={onSelectBySubscriber}
                onToggle={this.toggleCreateBatch}
                portfolios={portfolios}
                remainingCustomersCount={remainingCustomersCount}
                serverError={serverError}
                utilities={utilities}
                year={year}
              />
              <hr />
              {!billing ||
                (!!billing.length && (
                  <ul className="list-unstyled w-100 mb-0 pl-4 pr-4 pb-3">
                    <li className="nav-item m-1 d-block mb-2">
                      <Button block color="info" disabled={isFetching} onClick={onPreflight}>
                        Run Preflight Check
                      </Button>
                    </li>
                    <li className="nav-item m-1 d-block mb-2">
                      <Button
                        block
                        color="info"
                        disabled={isFetching || !canGenerate || isInvoicingBusy}
                        onClick={onGenerate}
                      >
                        Generate Statements
                      </Button>
                    </li>
                    <li className="nav-item m-1 d-block mb-2">
                      <Button
                        block
                        color="warning"
                        disabled={isFetching || !canAbort || isInvoicingBusy}
                        onClick={() => onToggleDeletingModal(true)}
                      >
                        Delete Statements
                      </Button>
                    </li>
                    <li className="nav-item m-1 d-block">
                      <Button
                        block
                        color="info"
                        disabled={isFetching || !canFinalize || isInvoicingBusy}
                        onClick={onFinalize}
                      >
                        Finalize Statements
                      </Button>
                    </li>
                  </ul>
                ))}
            </CardBody>
          </Card>
        </CardDeck>
        <footer className="text-center">
          <Button block color="link" onClick={handleDownloadExport}>
            <Icon className="mr-2" icon="save_alt" /> Download Full Status Report
          </Button>
        </footer>
      </>
    );
  }
}

export default compose(withRouter, withInvoiceContext)(InvoicesNavigation);
