import { css } from '@emotion/react';
import sctheme from 'app/styles/sctheme';

const styles = sctheme => css`
  .title-search {
    display: flex;
    width: 50%;
    margin-left: auto;
  }

  .title-search-input {
    overflow: hidden;
    transition: all 250ms ease-out;
    transform: scaleX(0);
    transform-origin: right;
  }

  .title-search-input--expanded {
    transform: scaleX(1);
  }

  .trash-can {
    cursor: pointer;
    color: ${sctheme.grays.$600};
  }

  .trash-can:hover {
    color: ${sctheme.red};
  }
`;

export default styles(sctheme);
