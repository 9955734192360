import { useCallback } from 'react';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { logout } from '../actions';

import { useAuth0 } from '@auth0/auth0-react';

export function useLogout() {
  const dispatch = useAppDispatch();
  const { logout: auth0Logout } = useAuth0();

  return useCallback(
    (options?: any, route = '/login') => {
      const logoutOptions = {
        logoutParams: {
          returnTo: `${window.location.origin}${route}`
        },
        ...options
      };
      dispatch(logout());

      auth0Logout(logoutOptions);
    },
    [dispatch, auth0Logout]
  );
}
