import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default class InvoicesDeleteModal extends Component {
  render() {
    const { month, year, isOpen, onCancel, onDelete } = this.props;
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>Delete Statements</ModalHeader>
        <ModalBody>
          <p>
            Deleting invocies will require you to re-run the generate statements function. All data and documents
            created with the last run will be deleted. All statements not yet finalized from {month}/{year} will be
            deleted.
          </p>
          <p>Any finalized statements will remain in the system.</p>
          <p>Are you sure you want to continue?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="warning" onClick={onDelete}>
            Delete all Statements
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
