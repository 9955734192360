import React, { FunctionComponent, ReactNode } from 'react';
import { Container } from 'reactstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styled from 'styled-components';

import SectionTitle from 'shared/components/SectionTitle';

const Wrapper = styled.section`
  margin: 30px 0;
  padding: 0 15px;
  width: 100%;
`;

type SectionProps = {
  id?: string;
  className?: string;
  icon?: string;
  title?: string;
  actions?: ReactNode;
  error?: boolean;
  searchQuery?: string | null;
  setSearchQuery?: any;
  faIcon?: IconProp;
  children: React.ReactNode;
  statusQuery?: any | null;
  setStatusQuery?: any;
  statuses?: any;
  placeholderText?: string;
  disabled?: boolean;
};

const Section: FunctionComponent<SectionProps> = ({
  id,
  className,
  icon,
  title,
  actions,
  error,
  faIcon,
  searchQuery,
  setSearchQuery,
  children,
  statusQuery,
  setStatusQuery,
  statuses,
  placeholderText,
  disabled = false
}) => {
  return (
    <Wrapper className={className} id={id}>
      <Container fluid={true}>
        <SectionTitle
          actions={actions}
          icon={icon}
          title={title}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          statusQuery={statusQuery}
          setStatusQuery={setStatusQuery}
          faIcon={faIcon}
          statuses={statuses}
          placeholderText={placeholderText}
          disabled={disabled}
        >
          {' '}
        </SectionTitle>
        {error && <div className="alert alert-danger">Error loading content</div>}
        {!error && children}
      </Container>
    </Wrapper>
  );
};

export default Section;
