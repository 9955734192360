import { createSelector } from 'reselect';

export function getSchedulesById(state) {
  return state.admin.schedules;
}

export const getSchedule = createSelector(
  [getSchedulesById, (_state, props) => props.match.params.scheduleId],
  (schedules, scheduleId) => schedules.byId[scheduleId]
);
