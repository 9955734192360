import cx from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { TableSearch } from 'shared/components/Tables/TableV2';
import Select from 'react-select';
import Icon from 'shared/components/Icon';
import styled from 'styled-components';
import sctheme from 'app/styles/sctheme';

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 700;
  padding: 15px 15px 15px 0;
  font-weight: 700;
  color: ${sctheme.grays.$900};
  display: flex;
  align-items: center;
`;

export const TitleIcon = styled(Icon)`
  color: ${props => props.theme.primary};
  margin-right: 10px;
`;

export const ActionButton = styled.div`
  &.disabled {
    pointer-events: none;
    button {
      border: 1px solid ${sctheme.grays.$300} !important;
      background-color: ${sctheme.grays.$200};
      color: ${sctheme.grays.$700} !important;
      svg {
        color: ${sctheme.grays.$700};
      }
    }
  }
`;

type SectionTitleProps = {
  id?: string;
  className?: string;
  icon?: string;
  title?: string;
  actions?: ReactNode;
  error?: boolean;
  searchQuery?: string | null;
  setSearchQuery?: any;
  statusQuery?: any | null;
  setStatusQuery: any;
  statuses?: any;
  faIcon?: IconProp;
  children: React.ReactNode | null;
  placeholderText?: string;
  disabled?: boolean;
};

const SectionTitle: FunctionComponent<SectionTitleProps> = ({
  children,
  className,
  icon,
  title,
  actions,
  faIcon,
  searchQuery,
  setSearchQuery,
  setStatusQuery,
  statusQuery,
  statuses,
  placeholderText,
  disabled
}) => {
  let renderIcon;
  if (faIcon) {
    renderIcon = faIcon && (
      <FontAwesomeIcon icon={faIcon} size="1x" style={{ color: sctheme.grays.$900, marginRight: '10px' }} />
    );
  } else {
    renderIcon = icon && <TitleIcon icon={icon} />;
  }

  const renderActions = actions && (
    <ActionButton className={`d-flex align-items-center ml-auto ${disabled ? 'disabled' : ''}`}>{actions}</ActionButton>
  );
  const renderSearch = setSearchQuery && (
    <TableSearch setSearchQuery={setSearchQuery} searchQuery={searchQuery} placeholderText={placeholderText} />
  );
  const renderStatusFilter = setStatusQuery && (
    <div style={{ width: '11rem', fontSize: '1rem', fontWeight: 'normal' }}>
      <Select
        getOptionLabel={({ name }: any) => name}
        getOptionValue={({ id }: any) => id}
        isClearable
        name="operationStatus"
        onChange={(statusQuery: { id: any; name: any }) =>
          setStatusQuery({ id: statusQuery.id, name: statusQuery.name })
        }
        options={statuses}
        value={statusQuery.name}
        placeholder={`Status: ${statusQuery.name}`}
      />
    </div>
  );

  const minHeight = setSearchQuery ? { minHeight: '5rem' } : { minHeight: 'auto' };

  return title ? (
    <div className={cx('d-flex align-items-center position-relative', className)} style={minHeight}>
      <Title>
        {renderIcon}
        {title}
        {renderSearch}
        {renderStatusFilter}
      </Title>

      {children && children}
      {renderActions}
    </div>
  ) : null;
};

export default SectionTitle;
