import Fuse from 'fuse.js';
import { func } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Card, CardBody, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import TruncatedText from 'shared/components/TruncatedText';
import { fetchSimplifiedSubscribers } from 'shared/store/modules/subscribers/effects';
import { getSubscribers, selectSubscribersStatus } from 'shared/store/modules/subscribers/selectors';
import { CustomerType } from 'shared/models';
import sctheme from 'app/styles/sctheme';

import Error from 'shared/components/Error';
import Loading from 'shared/components/Loading';

class InvoicesSelectBySubscriberModal extends Component {
  static propTypes = {
    onSubmit: func.isRequired
  };

  state = {
    name: '',
    query: '',
    selectedSubscribers: [],
    matchedSubscribers: []
  };

  constructor() {
    super();

    this.handleCheckAllClick = this.handleCheckAllClick.bind(this);
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateName(prevState);
  }

  updateName(prevState) {
    const { subscribers } = this.props;
    const { selectedSubscribers } = this.state;

    if (prevState.selectedSubscribers.length !== selectedSubscribers.length) {
      let name = '';
      if (selectedSubscribers.length === 1) {
        name = subscribers.find(item => item.id === selectedSubscribers[0]).displayName;
      }
      if (selectedSubscribers.length > 1) {
        name = `${selectedSubscribers.length} subscribers`;
      }
      if (selectedSubscribers.length === subscribers.length) {
        name = 'All subscribers';
      }

      this.setState({ name });
    }
  }

  handleSubmit() {
    const { onSubmit } = this.props;
    const { selectedSubscribers, name } = this.state;
    onSubmit(selectedSubscribers, name);
  }

  handleCheckboxClick(e) {
    if (!e.target.checked) {
      this.setState({
        selectedSubscribers: this.state.selectedSubscribers.filter(subscriber => {
          return subscriber !== e.target.value;
        })
      });
    } else {
      this.setState({
        selectedSubscribers: [...this.state.selectedSubscribers, e.target.value]
      });
    }
  }

  handleCheckAllClick() {
    if (this.state.selectedSubscribers.length === this.props.subscribers.length) {
      this.setState({
        selectedSubscribers: []
      });
    } else {
      this.setState({
        selectedSubscribers: this.props.subscribers.map(subscriber => subscriber.id)
      });
    }
  }

  handleSearchChange(e) {
    const fuse = new Fuse(this.props.subscribers, {
      distance: 100,
      keys: ['displayName'],
      location: 0,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      shouldSort: true,
      threshold: 0.2
    });

    const matches = fuse.search(e.target.value);

    this.setState({
      [e.target.name]: e.target.value,
      matchedSubscribers: matches.map(match => match.id)
    });
  }

  handleNameChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  componentDidMount() {
    this.props.fetchSimplifiedSubscribers({
      customerType: CustomerType.All,
      pageSize: 1000,
      onlyActiveAllocations: true
    });
  }

  render() {
    const { isOpen, onCancel } = this.props;

    let visibleSubscribers = [];

    if (this.state.query.trim() !== '') {
      visibleSubscribers = this.props.subscribers.filter(subscriber =>
        this.state.matchedSubscribers.includes(subscriber.id)
      );
    } else {
      visibleSubscribers = this.props.subscribers;
    }

    const checkboxRef = checkbox => {
      if (checkbox) {
        if (this.props.subscribers.length > 0) {
          if (
            this.state.selectedSubscribers.length !== this.props.subscribers.length &&
            this.state.selectedSubscribers.length !== 0
          ) {
            checkbox.indeterminate = true;
          } else {
            checkbox.indeterminate = false;
          }
        }
      }
    };

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>Select by Subscriber</ModalHeader>
        {this.props.subscriberStatus === 'fulfilled' && (
          <>
            <ModalBody style={{ backgroundColor: sctheme.grays.$200 }}>
              <Input
                className="mb-3"
                name="query"
                onChange={this.handleSearchChange}
                placeholder="Search&hellip;"
                value={this.state.query}
              />

              {!!this.props.subscribers.length && !visibleSubscribers.length && (
                <div className="alert alert-info">No results</div>
              )}

              {!!this.props.subscribers.length && !!visibleSubscribers.length && (
                <Card>
                  <CardBody>
                    <table className="table table-flex table-scroll">
                      <thead>
                        <tr>
                          <th className="col-6">
                            <div className="form-check">
                              <label className="mb-0">
                                <input
                                  checked={this.state.selectedSubscribers.length === this.props.subscribers.length}
                                  className="form-check-input"
                                  onChange={this.handleCheckAllClick}
                                  ref={checkboxRef}
                                  type="checkbox"
                                />
                                Subscriber Name
                              </label>
                            </div>
                          </th>
                          <th className="col-6 text-right">ID</th>
                        </tr>
                      </thead>

                      <tbody>
                        {visibleSubscribers.map(subscriber => (
                          <tr key={subscriber.id}>
                            <td className="col-6">
                              <div className="form-check">
                                <TruncatedText>
                                  <label className="mb-0 d-inline-block" style={{ maxWidth: '300px' }}>
                                    <input
                                      checked={this.state.selectedSubscribers.includes(subscriber.id)}
                                      className="form-check-input"
                                      onChange={this.handleCheckboxClick}
                                      type="checkbox"
                                      value={subscriber.id}
                                    />

                                    {subscriber.displayName}
                                  </label>
                                </TruncatedText>
                              </div>
                            </td>

                            <td className="col-6 justify-content-end">
                              <code>{subscriber.id.split('-')[0].toUpperCase()}</code>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              )}

              <div className="form-group mt-3">
                <label htmlFor="batch-name">Batch Name</label>
                <Input
                  className="mb-3"
                  id="batch-name"
                  name="name"
                  onChange={this.handleNameChange}
                  placeholder="Batch Name"
                  value={this.state.name}
                />
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                color="link"
                onClick={e => {
                  this.setState({
                    query: '',
                    selectedSubscribers: [],
                    matchedSubscribers: []
                  });
                  onCancel(e);
                }}
              >
                Cancel
              </Button>

              <Button color="info" disabled={this.state.selectedSubscribers.length === 0} onClick={this.handleSubmit}>
                Create Batch
              </Button>
            </ModalFooter>
          </>
        )}
        {this.props.subscriberStatus === 'rejected' && <Error children={'Something went wrong.'} error={undefined} />}
        {(this.props.subscriberStatus === 'idle' || this.props.subscriberStatus === 'pending') && <Loading />}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  subscribers: getSubscribers(state),
  subscriberStatus: selectSubscribersStatus(state)
});

const mapDispatchToProps = {
  fetchSimplifiedSubscribers
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoicesSelectBySubscriberModal));
