import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default class InvoicesFinalizeModal extends Component {
  render() {
    const { month, year, isOpen, onCancel, onSubmit } = this.props;
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>Finalize Statements</ModalHeader>
        <ModalBody>
          <p>Finalizing statements is not reversible. Continuing will execute the following:</p>
          <ul>
            <li>Send email to all subscribers</li>
            <li>Journal all the notices</li>
            <li>Post Statements to subscriber accounts</li>
            <li>Schedule autopayments for subscribers who are enrolled</li>
          </ul>
          <p>
            All {month}/{year} statements will be sent. Are you sure you want to continue?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="info" onClick={onSubmit}>
            Continue
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
