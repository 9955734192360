import { css } from '@emotion/react';
import sctheme from 'app/styles/sctheme';

const styles = sctheme => css`
  .batch-list-item {
    display: flex;
    justify-content: space-between;
  }

  .trash-can {
    cursor: pointer;
    color: ${sctheme.grays.$600};
  }

  .trash-can:hover {
    color: ${sctheme.red};
  }
`;

export default styles(sctheme);
