import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { InvoiceRedirectTo, InvoicesIndex, InvoicesShow } from 'app/Portal/Admin/Invoices';
import asyncComponent from 'shared/utilities/asyncComponent';
import { useSelector } from 'react-redux';

const Allocations = asyncComponent(() => import('./Allocations').then(module => module.Allocations));
const Config = asyncComponent(() => import('./Config').then(module => module.default));
const Dashboard = asyncComponent(() => import('./Dashboard').then(module => module.default));
const NotFound = asyncComponent(() => import('app/Portal/NotFound').then(({ NotFound }) => NotFound));
const Portfolios = asyncComponent(() => import('./Portfolios').then(module => module.default));
const Profile = asyncComponent(() => import('app/Portal/Profile').then(module => module.default));
const Projects = asyncComponent(() => import('./Projects').then(module => module.default));
const ReferralCodes = asyncComponent(() => import('./ReferralCodes').then(module => module.ReferralCodes));
const Reports = asyncComponent(() => import('./Reports').then(module => module.default));
const Settings = asyncComponent(() => import('./Settings').then(module => module.default));
const Subscribers = asyncComponent(() => import('./Subscribers').then(module => module.default));
const Tools = asyncComponent(() => import('./Tools').then(module => module.default));
const Utilities = asyncComponent(() => import('./Utilities').then(module => module.default));

export const canSeeAllocations = shardKey => {
  const shardKeysToShow = [
    '4990ec22-501d-46b8-ab3c-70ef33425cbe', // tonic.local
    'd7636e57-7ea1-7fb6-0b47-bf48720f19da', // dev1.dev.suncentral.net
    'BA66DCE3-3EA1-4B9A-A461-7B05772E1AC1', // qa.suncentral.net
    '57031165-B918-43FD-A555-4B0AEABCFD35', // localhost:3016
    '7FE1B620-4293-4247-BE3F-231C6188356C', // shard1-dev3.dev-suncentral.net
    '641e7390-74ea-d55a-2006-9ef062dd0385', // tonic prod
    '7f526d0f-7cfb-39d0-c632-ebee354e2913' // tonic.staging.suncentral.net
  ];
  if (shardKeysToShow.includes(shardKey)) return true;
  else return false;
};

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const shardKey = useSelector(state => state.provider?.key);
  return (
    <Route
      {...rest}
      render={props => {
        if (!canSeeAllocations(shardKey)) {
          return <Redirect to="/admin" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

const AdminRoutes = () => (
  <Switch>
    <ProtectedRoute component={Allocations} path="/admin/allocations" />
    <Route component={InvoicesShow} path="/admin/invoices/:month/:year/:billingId" />
    <Route component={InvoicesIndex} exact path="/admin/invoices/:month/:year" />
    <Route component={InvoiceRedirectTo} path="/admin/invoices" />
    <Route component={Portfolios} path="/admin/portfolios" />
    <Route component={Projects} path="/admin/projects" />
    <Route component={Subscribers} path="/admin/subscribers" />
    <Route component={Utilities} path="/admin/utilities" />
    <Route component={Reports} path="/admin/reports" />
    <Route component={ReferralCodes} path="/admin/promos" />
    <Route component={Settings} exact path="/admin/settings" />
    <Route component={Tools} exact path="/admin/tools" />
    <Route component={Config} path="/admin/config" />
    <Route component={Dashboard} exact path="/admin" />
    <Route component={Profile} path="/profile" />
    <Route component={() => <Redirect to="/admin" />} exact path="/" />
    <Route component={NotFound} />
  </Switch>
);

export default AdminRoutes;
