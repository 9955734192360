export function getAllocationsById(state) {
  return state.admin.allocations;
}

export function getAllocation(state, props) {
  const allocation = state.admin.allocations.byId[props.match.params.allocationId];

  if (allocation) {
    return allocation;
  }

  return {};
}
