import { createSelector } from 'reselect';

export function getReadingsById(state) {
  return state.admin.readings;
}

export const getReading = createSelector(
  [getReadingsById, (_, props) => props.match.params.readingId],
  (readings, readingId) => readings.byId[readingId]
);
