import download from 'downloadjs';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';

import Section from 'shared/components/Section';
import {
  fetchYears,
  getRemainingCustomersEstimate,
  getBillingByDate,
  deleteInvoice,
  billingDeleteAllCustomers,
  billingDeleteAllPreflight,
  createBillingWithCustomers,
  createRemainingCustomers,
  createBillingFromFilters,
  runPreflightCheck,
  generateInvoices,
  invoiceFinalize,
  invoiceGenerateStatusExport
} from 'shared/store/modules/invoices/effects';
import { fetchPortfolios } from 'shared/store/modules/portfolios/effects';
import { fetchUtilities } from 'shared/store/modules/utilities/effects';
import toast from 'shared/utilities/toast';

import { InvoiceContext } from './InvoicesContext';
import InvoicesCreateRemainingModal from './components/InvoicesCreateRemainingModal';
import InvoicesDeleteModal from './components/InvoicesDeleteModal';
import InvoicesFinalizeModal from './components/InvoicesFinalizeModal';
import InvoicesNavigation from './components/InvoicesNavigation';
import InvoicesSelectBySubscriberModal from './components/InvoicesSelectBySubscriberModal';
import { invoicesActivityEnd } from 'shared/store/modules/invoices/actions';

class InvoicesLayout extends Component {
  static defaultProps = {
    queuedToGenerateInvoiceCount: null,
    queuedToFinalizeInvoiceCount: null,
    queuedToAbortInvoiceCount: null
  };

  state = {
    isDeletingModalOpen: false,
    billingDates: null,
    isFinalizeModalOpen: false,
    isCreateRemainingModalOpen: false,
    isSelectBySubscriberModalOpen: false
  };

  componentDidMount() {
    const { actions } = this.props;
    actions.fetchYears();
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { month, year } = this.props.match.params;
    if (prevProps.match.params.month !== month || prevProps.match.params.year !== year) {
      this.fetchData();
    }
  }

  UNSAFE_componentWillReceiveProps({
    match,
    invoiceStatusExportData,
    preflightExportData,
    queuedToGenerateInvoiceCount,
    queuedToFinalizeInvoiceCount,
    queuedToAbortInvoiceCount
  }) {
    const {
      params: { month, year }
    } = match;

    if (invoiceStatusExportData !== this.props.invoiceStatusExportData && invoiceStatusExportData) {
      download(invoiceStatusExportData, `${month}-${year}_Statement-Status-Export.csv`, 'text/csv');
    }

    if (preflightExportData !== this.props.preflightExportData && preflightExportData) {
      download(preflightExportData, `${month}-${year}_Preflight-Export.csv`, 'text/csv');
    }

    if (this.props.isProcessing) {
      if (
        queuedToGenerateInvoiceCount !== null &&
        queuedToGenerateInvoiceCount !== this.props.queuedToGenerateInvoiceCount
      ) {
        if (queuedToGenerateInvoiceCount > 0) {
          toast.success(`Statement generated! ${queuedToGenerateInvoiceCount} remain`);
        } else if (queuedToGenerateInvoiceCount === 0) {
          toast.success(`Statements fully generated!`);
        }
      }

      if (queuedToAbortInvoiceCount !== null && queuedToAbortInvoiceCount !== this.props.queuedToAbortInvoiceCount) {
        if (queuedToAbortInvoiceCount > 0) {
          toast.success(`Statement deleted! ${queuedToAbortInvoiceCount} remain`);
        } else if (queuedToAbortInvoiceCount === 0) {
          toast.success(`Statements fully deleted!`);
        }
      }

      if (
        queuedToFinalizeInvoiceCount !== null &&
        queuedToFinalizeInvoiceCount !== this.props.queuedToFinalizeInvoiceCount &&
        queuedToFinalizeInvoiceCount > 0
      ) {
        if (queuedToFinalizeInvoiceCount > 0) {
          toast.success(`Statement finalized! ${queuedToFinalizeInvoiceCount} remain`);
        } else if (queuedToFinalizeInvoiceCount === 0) {
          toast.success(`Statements fully finalized!`);
        }
      }
    }
  }

  fetchData() {
    const { actions, match } = this.props;
    const {
      params: { month, year }
    } = match;
    actions.fetchUtilities();
    actions.fetchPortfolios();
    actions.getRemainingCustomersEstimate({ month, year });
    actions.getBillingByDate({ month, year });
  }

  toggleDeletingModal = (status = null) => {
    this.setState(prevState => ({
      isDeletingModalOpen: status ? status : !prevState.isDeletingModalOpen
    }));
  };

  toggleCreateRemainingModal = (status = null) => {
    this.setState(prevState => ({
      isCreateRemainingModalOpen: status ? status : !prevState.isCreateRemainingModalOpen
    }));
  };

  handleCreateRemaining = () => {
    const {
      actions,
      match: {
        params: { month, year }
      }
    } = this.props;
    this.toggleCreateRemainingModal(false);
    actions.createRemainingCustomers({ month, year });
  };

  handleCreateFromFilters = payload => {
    const { actions } = this.props;
    actions.createBillingFromFilters(payload);
  };

  handleDelete = () => {
    const {
      actions,
      match: {
        params: { billingId }
      }
    } = this.props;
    actions.deleteInvoice(billingId).then(data => {
      actions.invoicesActivityEnd(data.payload);
    });

    this.setState(() => ({
      isDeletingModalOpen: false
    }));
  };

  handlePrefight = () => {
    const {
      actions,
      match: {
        params: { billingId }
      }
    } = this.props;
    actions.runPreflightCheck(billingId);
  };

  handleGenerate = () => {
    const {
      actions,
      match: {
        params: { billingId }
      }
    } = this.props;

    actions.generateInvoices(billingId);
  };

  handleFinalize = () => {
    this.setState({
      isFinalizeModalOpen: true
    });
  };

  handleSelectBySubscriber = () => {
    this.setState({
      isSelectBySubscriberModalOpen: true
    });
  };

  handleSelectBySubscriberSubmit = (customerIds, name) => {
    const {
      actions,
      match: {
        params: { month: forMonth, year: forYear }
      }
    } = this.props;

    const payload = {
      name,
      customerIds,
      forMonth,
      forYear
    };
    actions.createBillingWithCustomers(payload);

    this.setState({
      isSelectBySubscriberModalOpen: false
    });
  };

  readyToFinalize = () => {
    const {
      actions,
      match: {
        params: { billingId }
      }
    } = this.props;
    actions.invoiceFinalize(billingId);

    this.setState({
      isReadyToFinalize: true,
      isFinalizeModalOpen: false
    });
  };

  isNotReadyToFinalize = () => {
    this.setState({
      isFinalizeModalOpen: false
    });
  };

  handleDownloadExport = () => {
    const {
      match: {
        params: { billingId }
      }
    } = this.props;
    const { actions } = this.props;
    actions.invoiceGenerateStatusExport(billingId);
  };

  render() {
    const {
      remainingCustomersCount,
      isFetchingBillingDates,
      billingDates,
      serverError,
      match: {
        params: { month, year, billingId }
      },
      actions: { billingDeleteAllCustomers, billingDeleteAllPreflight },
      children,
      data,
      utilities,
      billing,
      portfolios,
      recentlyDeleted
    } = this.props;

    const {
      isCreateRemainingModalOpen,
      isDeletingModalOpen,
      isFinalizeModalOpen,
      isSelectBySubscriberModalOpen
    } = this.state;

    return (
      <InvoiceContext.Provider value={{ month, year, billingId, billing }}>
        <Helmet>
          <title>Statements</title>
        </Helmet>

        <Section icon="assignment" title="Statements">
          <Row>
            <Col md={4}>
              <InvoicesNavigation
                billing={billing}
                billingDates={billingDates}
                billingId={billingId}
                data={data}
                handleDownloadExport={this.handleDownloadExport}
                isFetching={isFetchingBillingDates}
                isInvoicingBusy={this.props.isInvoicingBusy}
                onCreateFromFilters={this.handleCreateFromFilters}
                onFinalize={this.handleFinalize}
                onGenerate={this.handleGenerate}
                onPreflight={this.handlePrefight}
                onSelectBySubscriber={this.handleSelectBySubscriber}
                onToggleCreateRemainingModal={this.toggleCreateRemainingModal}
                onToggleDeletingModal={this.toggleDeletingModal}
                portfolios={portfolios}
                remainingCustomersCount={remainingCustomersCount}
                serverError={serverError}
                utilities={utilities}
                billingDeleteAllCustomers={billingDeleteAllCustomers}
                billingDeleteAllPreflight={billingDeleteAllPreflight}
                recentlyDeleted={recentlyDeleted}
              />
            </Col>
            <Col md={8}>{children}</Col>
          </Row>
        </Section>
        <InvoicesDeleteModal
          isOpen={!!isDeletingModalOpen}
          month={month}
          onCancel={() => this.toggleDeletingModal(false)}
          onDelete={this.handleDelete}
          year={year}
        />
        <InvoicesFinalizeModal
          isOpen={!!isFinalizeModalOpen}
          month={month}
          onCancel={this.isNotReadyToFinalize}
          onSubmit={this.readyToFinalize}
          year={year}
        />
        <InvoicesSelectBySubscriberModal
          isOpen={!!isSelectBySubscriberModalOpen}
          onCancel={() => this.setState({ isSelectBySubscriberModalOpen: false })}
          onSubmit={this.handleSelectBySubscriberSubmit}
        />
        <InvoicesCreateRemainingModal
          isOpen={!!isCreateRemainingModalOpen}
          month={month}
          onCancel={() => this.toggleCreateRemainingModal(false)}
          onSubmit={this.handleCreateRemaining}
          remainingCustomersCount={remainingCustomersCount}
          year={year}
        />
      </InvoiceContext.Provider>
    );
  }
}

const mapStateToProps = state => ({
  billing: state.invoices.billing,
  billingDates: state.invoices.billingDates,
  data: state.invoices.data,
  isFetching: state.invoices.isFetching,
  isFetchingBillingDates: state.invoices.isFetchingBillingDates,
  isInvoicingBusy: state.invoices.isInvoicingBusy,
  isProcessing: state.invoices.data.isProcessing,
  portfolios: state.admin.portfolios,
  queuedToAbortInvoiceCount: state.invoices.data.queuedToAbortInvoiceCount,
  queuedToFinalizeInvoiceCount: state.invoices.data.queuedToFinalizeInvoiceCount,
  queuedToGenerateInvoiceCount: state.invoices.data.queuedToGenerateInvoiceCount,
  remainingCustomersCount: state.invoices.remainingCustomersCount,
  serverError: state.invoices.error,
  statusMessage: state.invoices.statusMessage,
  utilities: state.admin.utilities,
  recentlyDeleted: state.invoices.recentlyDeleted
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchYears,
      fetchUtilities,
      fetchPortfolios,
      getRemainingCustomersEstimate,
      getBillingByDate,
      deleteInvoice,
      billingDeleteAllCustomers,
      billingDeleteAllPreflight,
      createRemainingCustomers,
      createBillingFromFilters,
      runPreflightCheck,
      generateInvoices,
      invoiceFinalize,
      invoicesActivityEnd,
      invoiceGenerateStatusExport,
      createBillingWithCustomers
    },
    dispatch
  )
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(InvoicesLayout);
