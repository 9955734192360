import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

export function getContractsById(state) {
  return state.admin.contracts.byId;
}

export function getContractIds(state) {
  return state.admin.contracts.allIds;
}

export const getContract = createSelector(
  [getContractsById, (_, contractId) => contractId],
  (contracts, contractId) => {
    return contracts[contractId] || {};
  }
);

export const getContracts = createSelector([getContractsById, getContractIds], (contracts = [], ids = []) => {
  contracts = ids.map(id => contracts[id]);
  contracts = contracts.filter(contract => !contract.deleted);
  contracts = orderBy(contracts, ['name'], ['asc']);

  return contracts;
});
