import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import { useAuth0Session } from 'shared/store/modules/auth/hooks';

export const LoginDispatch = () => {
  const { automaticDispatchToRoute, isAuthenticated, error } = useAuth0Session();

  useEffect(() => {
    if (isAuthenticated) {
      automaticDispatchToRoute();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  if (error) {
    console.error(error);

    // @ts-ignore
    return <Redirect to={`/login?error=${error.message}&error_type=${error.error}`} />;
  }

  return <Loading />;
};
