export const SubscriberType = {
  Commercial: 0,
  Residential: 1,
  0: 'Commercial',
  1: 'Residential'
};

const constants = {
  SubscriberType
};

export default constants;
