import { createSelector } from 'reselect';

export function getRateTypesById(state) {
  return state.admin.rateTypes;
}

export const getRateTypes = createSelector([getRateTypesById, (_, utilityId) => utilityId], (rateTypes, utilityId) => {
  const mapped = Object.keys(rateTypes.byId).map(key => {
    let rateType = rateTypes.byId[key];

    /*
        There is a bug somewhere in the system.
        Depending on how you navigate around, the byId entity may or may not record the utilityId as:

        utilityId: '', //(as a property) or
        utility: { //as an object
          id: '' 
        }

        I didn't have time to find the actual gremlin so there is some safety code below
      */
    if (rateType.utility) {
      rateType.utilityId = rateType.utility.id;
    }

    return rateType;
  });

  return mapped.filter(rateType => rateType.utilityId === utilityId);
});

export const getRateType = createSelector(
  [getRateTypesById, (_, props) => props.match.params.rateTypeId],
  (rateTypes, rateTypeId) => rateTypes.byId[rateTypeId]
);
