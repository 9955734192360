import { css } from '@emotion/react';

const styles = () => css`
  .search-wrapper {
    display: flex;
    margin: 0 1rem 0 1rem;
    align-items: center;
    position: relative;

    label {
      margin: 0;
      font-weight: 700 !important;
      padding-right: 0.5rem;
    }

    input {
      background-color: white;
    }

    .form-control {
      width: 225px;
      padding-left: 2rem;
    }

    .search-icon {
      position: absolute;
      left: 0.5rem;
      font-size: 16px;
      color: hsl(0, 0%, 50%);
    }
  }
`;

export default styles;
