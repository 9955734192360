import { css } from '@emotion/react';
import sctheme from 'app/styles/sctheme';

const styles = sctheme => css`
  .status em,
  .status-item em {
    display: block;
    margin: 0 0 5px;
    text-transform: uppercase;
    color: ${sctheme.grays.$700};
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    line-height: 18px;
  }

  .status-item {
    position: relative;
    padding-bottom: 35px;
  }

  .status-item h2 {
    position: absolute;
    bottom: 0;
    font-size: 24px;
    font-weight: bold;
  }

  .status-bar-completed {
    background: ${sctheme.system.successLight};
  }
  .status-bar-completed .progress-bar {
    background: ${sctheme.system.success};
  }

  .status-bar-failed {
    background: ${sctheme.system.warningLight};
  }
  .status-bar-failed .progress-bar {
    background: ${sctheme.system.warning};
  }

  .status-bar-default {
    background: ${sctheme.system.infoLight};
  }
  .status-bar-default .progress-bar {
    background: ${sctheme.system.info};
  }
`;

export default styles(sctheme);
