import { createSelector } from 'reselect';

export function getCreditBandsById(state) {
  return state.admin.creditBands;
}

export const getCreditBand = createSelector(
  [getCreditBandsById, (_, props) => props.match.params.creditBandId],
  (creditBands, creditBandId) => creditBands.byId[creditBandId] || {}
);
