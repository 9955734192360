import { normalize } from 'normalizr';

import { addEntities, deleteEntities } from 'shared/store/modules/entities';
import {
  createPortfolioRequest,
  createPortfolioResponse,
  exportPortfolioActivitiesRequest,
  exportPortfolioActivitiesResponse,
  exportPortfolioProductionReportRequest,
  exportPortfolioProductionReportResponse,
  fetchAllPortfoliosRequest,
  fetchAllPortfoliosResponse,
  fetchPortfolioDetailsRequest,
  fetchPortfolioDetailsResponse,
  fetchPortfolioRequest,
  fetchPortfolioResponse,
  fetchPortfolioSettlementDetailsRequest,
  fetchPortfolioSettlementDetailsResponse,
  settlePortfolioBalanceRequest,
  settlePortfolioBalanceResponse,
  updatePortfolioRequest,
  updatePortfolioResponse
} from 'shared/store/modules/portfolios/actions';
import toast from 'shared/utilities/toast';

export const fetchPortfolios = () => async (dispatch, _getState, { api, schemas }) => {
  dispatch(fetchAllPortfoliosRequest());

  try {
    const { data } = await api.portfolio.fetchAll();
    const portfolios = normalize(data, schemas.portfolios);

    dispatch(addEntities(portfolios));
    return dispatch(fetchAllPortfoliosResponse(portfolios));
  } catch (err) {
    return dispatch(fetchAllPortfoliosResponse(err));
  }
};

export const fetchPortfolio = id => async (dispatch, _getState, { api, schemas }) => {
  dispatch(fetchPortfolioRequest());

  try {
    const { data } = await api.portfolio.fetchOne(id);
    const portfolio = normalize(data, schemas.portfolio);

    dispatch(addEntities(portfolio));
    return dispatch(fetchPortfolioResponse(portfolio));
  } catch (err) {
    return dispatch(fetchPortfolioResponse(err));
  }
};

export const fetchPortfolioDetails = id => async (dispatch, _getState, { api, schemas }) => {
  dispatch(fetchPortfolioDetailsRequest());

  try {
    const { data } = await api.portfolio.fetchDetails(id);
    const portfolio = normalize(data, schemas.portfolio);

    dispatch(addEntities(portfolio));
    return dispatch(fetchPortfolioDetailsResponse(portfolio));
  } catch (err) {
    return dispatch(fetchPortfolioDetailsResponse(err));
  }
};

export const exportProductionReport = (id, period, type) => async (dispatch, _getState, { api }) => {
  dispatch(exportPortfolioProductionReportRequest());
  try {
    if (period === 'lifetime') {
      const { data } = await api.portfolio.exportProductionLifetime(id, type);
      return dispatch(exportPortfolioProductionReportResponse(data));
    } else {
      var year = period.split('-')[0];
      var month = period.split('-')[1];
      var { data } = await api.portfolio.exportProductionByMonth(id, year, month, type);
      return dispatch(exportPortfolioProductionReportResponse(data));
    }
  } catch (err) {
    return dispatch(exportPortfolioProductionReportResponse(err));
  }
};

export const createPortfolio = portfolio => async (dispatch, _getState, { api, schemas }) => {
  dispatch(createPortfolioRequest());

  try {
    const { data } = await api.portfolio.create(portfolio);
    toast.success('New portfolio created');
    return dispatch(createPortfolioResponse(normalize({ ...data, ...portfolio }, schemas.portfolio)));
  } catch (err) {
    toast.error(err);
    return dispatch(createPortfolioResponse(err));
  }
};

export const updatePortfolio = (id, updates) => async (dispatch, _getState, { api, schemas }) => {
  dispatch(updatePortfolioRequest());

  try {
    await api.portfolio.update(id, updates);
    toast.success('Portfolio updated');
    return dispatch(updatePortfolioResponse(normalize({ id, ...updates }, schemas.portfolio)));
  } catch (err) {
    toast.error(err);
    return dispatch(updatePortfolioResponse(err));
  }
};

export const deletePortfolio = id => async (dispatch, _getState, { api }) => {
  dispatch(updatePortfolioRequest());

  try {
    await api.portfolio.delete(id);
    toast.success('Portfolio deleted');
    return dispatch(deleteEntities({ id }));
  } catch (err) {
    toast.error(err);
    return dispatch(updatePortfolioResponse(err));
  }
};

export const settlePortfolioBalance = (portfolioId, amount, selectedBucketType) => async (
  dispatch,
  _getState,
  { api, schemas }
) => {
  dispatch(settlePortfolioBalanceRequest());

  return api.portfolio.settleBalance(portfolioId, amount, selectedBucketType).then(
    response => {
      let { data } = response;
      data = normalize({ ...data, portfolioId }, schemas.activity);
      dispatch(addEntities(data));
      toast.success('Balance successfully settled');
      return dispatch(settlePortfolioBalanceResponse(data));
    },
    error => {
      return dispatch(settlePortfolioBalanceResponse(error));
    }
  );
};

export const fetchPortfolioSettlementDetails = portfolioId => async (dispatch, _getState, { api, schemas }) => {
  dispatch(fetchPortfolioSettlementDetailsRequest());

  return api.portfolio.fetchSettlementDetails(portfolioId).then(
    response => {
      let data = {
        portfolio: {
          id: portfolioId
        },
        ...response.data
      };

      data = normalize(data, schemas.settlementAccount);
      dispatch(addEntities(data));

      return dispatch(fetchPortfolioSettlementDetailsResponse(data));
    },
    error => {
      return dispatch(fetchPortfolioSettlementDetailsResponse(error));
    }
  );
};

export const exportPortfolioActivities = portfolioId => async (dispatch, _getState, { api }) => {
  dispatch(exportPortfolioActivitiesRequest());

  try {
    const { data } = await api.portfolio.exportActivities(portfolioId);
    return dispatch(exportPortfolioActivitiesResponse(data));
  } catch (err) {
    return dispatch(exportPortfolioActivitiesResponse(err));
  }
};
