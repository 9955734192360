export class CustomerService {
  http;

  constructor(http) {
    this.http = http;
  }
  // GET /customers
  async fetchAll(params) {
    return this.http.get('/customers', {
      params
    });
  }

  async fetchAllSimplified(params) {
    return this.http.get('/customers/simplified', {
      params
    });
  }

  async fetchAllByUtility(utilityId) {
    return this.http.get(`/utilities/${utilityId}/customers`);
  }

  async fetchAutoAllocatableCustomers(utilityId, effectiveDate) {
    return this.http.get(`/utilities/${utilityId}/customers/auto-allocatable/${effectiveDate}`);
  }

  // GET /customers/{customerId}
  async fetchOne(customerId) {
    return this.http.get(`/customers/${customerId}`);
  }

  // GET /customers/{customerId}/details
  async fetchDetails(customerId, portfolioId) {
    return this.http.get(`/customers/${customerId}/details`, {
      params: {
        portfolioId
      }
    });
  }

  async exportActivities(customerId) {
    return this.http.get(`/customers/${customerId}/account-activity/export`);
  }

  // GET /customers/{customerId}
  async getCustomer(customerId) {
    return this.http.get(`/customers/${customerId}`);
  }

  // POST /customers
  async create(model) {
    return this.http.post('/customers', model);
  }

  // PUT /customers
  async update(id, model) {
    return this.http.put('/customers', { id, ...model });
  }

  // PUT /customers
  async updateCustomer(id, model) {
    return this.http.put('/customers', { id, ...model });
  }

  // POST /customers/subscriber-user
  async createUser(model) {
    return this.http.post('/customers/subscriber-user', model);
  }

  // DELETE /customers
  async deleteCustomer(customerId) {
    return this.http.delete(`/customers/${customerId}`);
  }

  // POST /customers/subscriptions
  async createCustomerSubscription(model) {
    return this.http.post('/customers/subscriptions', model);
  }

  // PUT /customers/subscriptions
  async updateCustomerSubscription(model) {
    return this.http.put('/customers/subscriptions', model);
  }

  // GET /customers/{customerId}/dashboard
  async getCustomerDashboard(customerId) {
    return this.http.get(`/customers/${customerId}/dashboard`);
  }

  // GET /customers/{customerId}/subscription
  async getCustomerSubscription(customerId) {
    return this.http.get(`/customers/${customerId}/subscription`);
  }

  // GET /customers/{subscriberId}/subscription/{subscriptionId}/savings
  async getCustomerEstimatedSavings(customerId, subscriptionId) {
    return this.http.get(`/customers/${customerId}/subscription/${subscriptionId}/savings`);
  }

  // GET /customers/{customerId}/projects
  async getCustomerProjects(customerId) {
    return this.http.get(`/customers/${customerId}/projects`);
  }

  // GET /customers/{customerId}/projects/{projectId}
  async getCustomerProject(customerId, projectId) {
    return this.http.get(`/customers/${customerId}/projects/${projectId}`);
  }

  // GET /customers/{customerId}/projects/{projectId}/export
  async exportCustomerProject(customerId, projectId) {
    return this.http.get(`/customers/${customerId}/projects/${projectId}/export`);
  }

  // GET /customers/{customerId}/premises/{premiseId}
  async getCustomerPremise(customerId, premiseId) {
    return this.http.get(`/customers/${customerId}/premises/${premiseId}`);
  }

  // GET /customers/{customerId}/premises/{premiseId}/export
  async exportCustomerPremise(customerId, premiseId) {
    return this.http.get(`/customers/${customerId}/premises/${premiseId}/export`);
  }

  // GET /customers/{customerId}/invoices
  async getCustomerInvoices(customerId) {
    return this.http.get(`/customers/${customerId}/invoices`);
  }

  // GET /customers/invoices/document/{documentId}
  async getCustomerInvoiceByDocId(invoiceDocumentId) {
    return this.http.get(`/customers/invoices/document/${invoiceDocumentId}`, {
      responseType: 'blob'
    });
  }

  // GET /customers/invoices/{invoiceId}
  async getCustomerInvoice(invoiceId) {
    return this.http.get(`/customers/invoices/${invoiceId}`, {
      responseType: 'blob'
    });
  }

  // GET /customers/{customerId}/documents
  async getCustomerDocuments(customerId) {
    return this.http.get(`/customers/${customerId}/documents`);
  }

  // GET /customers/documents/{documentId}
  async getCustomerDocument(documentId) {
    return this.http.get(`/customers/documents/${documentId}`, {
      responseType: 'blob'
    });
  }

  async getCustomerBalance(customerId) {
    return this.http.get(`/customers/${customerId}/balance`);
  }

  async downloadCustomerInvoice(invoiceId) {
    return this.http.get(`/customers/invoices/${invoiceId}`, {
      responseType: 'blob'
    });
  }

  // POST /customers/payment-sources/card
  async addCard(payload) {
    payload.provider = 0; // required parameter
    return this.http.post('/customers/payment-sources/card', payload);
  }

  // POST /customers/payment-sources/bank-account
  async addBankAccount(payload) {
    return this.http.post('/customers/payment-sources/bank-account', payload);
  }

  // POST /customers/payment-sources/plaid-verified-bank-account
  async addPlaidVerifiedBankAccount(payload) {
    return this.http.post('/customers/payment-sources/plaid-verified-bank-account', payload);
  }

  async addPlaidPendingBankAccount(payload) {
    return this.http.post('/customers/payment-sources/plaid-pending-bank-account', payload);
  }

  async getPendingPlaidToken(paymentSourceId) {
    return this.http.post('/customers/payment-sources/get-pending-plaid-token?paymentSourceId=' + paymentSourceId);
  }

  // DELETE /customers/payment-sources
  async deletePaymentSource(customerId, sourceId) {
    return this.http.delete('/customers/payment-sources', {
      data: {
        customerId,
        sourceId
      }
    });
  }

  async deletePlaidPendingPaymentSource(customerId, sourceId) {
    return this.http.delete('/customers/payment-sources/remove-pending-plaid-source', {
      data: {
        customerId,
        sourceId
      }
    });
  }

  // PATCH /customers/payment-sources/primary
  async setPrimaryPaymentSource(customerId, paymentSourceId) {
    return this.http.patch('/customers/payment-sources/primary', {
      customerId,
      paymentSourceId
    });
  }

  // PATCH /customers/payment-sources/primary
  async customerUpdateCard(customerId, paymentSourceId, values) {
    return this.http.patch(`/customers/${customerId}/payment-sources/card/${paymentSourceId}`, values);
  }

  // GET /customers/{customerId}/payment-sources
  async getCustomerPaymentSources(customerId, onlyUsable = true) {
    return this.http.get(`/customers/${customerId}/payment-sources`, {
      params: {
        onlyUsable
      }
    });
  }

  // GET /customers/{customerId}/waitlist-info
  async getCustomerWaitlistInfo(customerId) {
    return this.http.get(`/customers/${customerId}/waitlist-info`);
  }

  // GET /customers/{customerId}/provisional-dashboard
  async getProvisionalSubscriptionInfo(customerId) {
    return this.http.get(`/customers/${customerId}/provisional-dashboard`);
  }

  // GET /customers/{customerId}/billing
  async getCustomerBilling(customerId) {
    return this.http.get(`/customers/${customerId}/billing`);
  }

  // PUT /customers/auto-payments/opt-in
  async optOutAutoPayments(customerId) {
    return this.http.put(`/customers/auto-payments/opt-in`, {
      customerId,
      optIn: false
    });
  }

  // PUT /customers/auto-payments/opt-in
  async optInAutoPayments(customerId) {
    return this.http.put(`/customers/auto-payments/opt-in`, {
      customerId,
      optIn: true
    });
  }

  // GET /customers/{customerId}/auto-payments/opt-in
  async autoPaymentsStatus(customerId) {
    return this.http.get(`customers/${customerId}/auto-payments/opt-in`);
  }

  // POST /customers/payment-sources/verify-bank-account
  async verifyBankAccount(payload) {
    return this.http.post(`/customers/payment-sources/verify-bank-account`, payload);
  }

  async fetchDocuments(customerId) {
    return this.http.get(`/customers/${customerId}/documents`);
  }

  async uploadDocument(formData) {
    return this.http.post('/customers/documents/upload', formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

  async deleteDocument(documentId) {
    return this.http.delete('/customers/documents', {
      params: {
        documentId
      }
    });
  }

  async exportAllActivity(beginDate, endDate, ledgerEntryType, renderMasthead = false) {
    return this.http.get('/customers/activity-report/export?', {
      params: {
        beginDate,
        endDate,
        ledgerEntryType,
        renderMasthead
      }
    });
  }

  async fetchDeferredCharges(customerId) {
    return this.http.get(`/customers/${customerId}/invoices/deferred-items`);
  }

  async createDeferredCharge(deferredCharge) {
    return this.http.post('/customers/invoices/deferred-items', deferredCharge);
  }

  async deleteDeferredCharge(id) {
    return this.http.delete(`/customers/invoices/deferred-items/${id}`);
  }

  async editDeferredCharge(deferredCharge) {
    return this.http.put('/customers/invoices/deferred-items', deferredCharge);
  }
}
