import { withFormik } from 'formik';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormGroup } from 'reactstrap';
import { compose } from 'recompose';
import * as Yup from 'yup';

import Loading from 'shared/components/Loading';
import MonthsDropdown from 'shared/components/MonthsDropdown';
import YearsDropdown from 'shared/components/YearsDropdown';

class PeriodForm extends Component {
  static defaultProps = {
    billingDates: {}
  };

  componentDidUpdate({ availableMonths, values: { month, year }, ...rest }) {
    const {
      billingDates,
      history,
      values: { month: nextMonth, year: nextYear }
    } = this.props;

    let monthGoTo = parseInt(nextMonth, 0);

    if (month !== nextMonth || year !== nextYear) {
      const available = billingDates ? billingDates[nextYear] : null;
      // if our present month doesn't exist in the options
      // navigate to the first month available.
      if (available && !available.includes(monthGoTo)) {
        monthGoTo = available[0];
      }

      available && history.push(`/admin/invoices/${monthGoTo}/${nextYear}`);
    }
  }

  render() {
    const { isFetching, handleSubmit, availableYears, availableMonths } = this.props;

    if (isFetching) return <Loading />;

    return (
      <Form autoComplete="nope" onSubmit={handleSubmit}>
        <FormGroup className="d-flex mb-0">
          {!!availableYears.length && (
            <YearsDropdown
              className="mr-2"
              handleChange={handleSubmit}
              name="year"
              years={availableYears}
              {...this.props}
            />
          )}
          {!!availableMonths.length && (
            <MonthsDropdown handleChange={handleSubmit} months={availableMonths} name="month" {...this.props} />
          )}
        </FormGroup>
      </Form>
    );
  }
}

export default compose(
  withRouter,
  withFormik({
    mapPropsToValues: ({ month, year }) => {
      return {
        month,
        year
      };
    },
    validationSchema: Yup.object().shape({
      year: Yup.string().required(),
      month: Yup.string().required()
    })
  })
)(PeriodForm);
