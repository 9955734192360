import React, { Component } from 'react';
import { Alert, Button, Modal, ModalBody } from 'reactstrap';

import Icon from 'shared/components/Icon';

class PreflightModal extends Component {
  static displayName = 'PreflightModal';

  render() {
    const { onClearPreflight, onDownloadPreflight, month, year, preflightCheck } = this.props;

    if (preflightCheck && !!preflightCheck.alerts.length) {
      return (
        <Modal isOpen={!!preflightCheck}>
          <ModalBody className="p-0">
            <Alert className="m-0" color="danger">
              <h6 className="d-flex align-items-center mb-3">
                <Icon className="mr-2" icon="warning" />{' '}
                <span>Errors found in preflight check. Review report for details.</span>
              </h6>
              <div className="d-flex align-items-center mt-2">
                <Button className="d-flex align-items-center" color="info" onClick={onDownloadPreflight} size="sm">
                  <Icon className="mr-2" icon="cloud_download" />{' '}
                  <span>
                    Download {month}-{year}
                    _Preflight.csv
                  </span>
                </Button>
                <Button className="ml-auto" color="danger" onClick={onClearPreflight} size="sm">
                  Close
                </Button>
              </div>
            </Alert>
          </ModalBody>
        </Modal>
      );
    } else if (preflightCheck) {
      return (
        <Alert className="p-3" color="success">
          No Preflight issues found.
        </Alert>
      );
    }
  }
}

export default PreflightModal;
