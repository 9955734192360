// USE FOR SERVER SIDE TABLE PAGINATION (i.e. each page of data is called indivitually from the BE)
import React, { useEffect, useState, useCallback } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import Select from 'react-select';

const TablePaginationManual = props => {
  const {
    gotoPage,
    previousPage,
    nextPage,
    pageCount,
    setTablePage,
    currentPage,
    tableSize,
    setTableSize,
    pagingData
  } = props;

  const [visiblePages, setVisiblePages] = useState([]);

  const { totalPages } = pageCount;
  const { totalCount } = pagingData;

  const filterPages = (visiblePages, pageCount) => {
    return visiblePages.filter(page => page <= pageCount);
  };

  const getVisiblePages = useCallback(
    (page, pageCount) => {
      const pageToUse = page ? page : currentPage;
      if (pageCount < 7) {
        return setVisiblePages(filterPages([1, 2, 3, 4, 5, 6], pageCount));
      } else {
        if (pageToUse % 5 >= 0 && pageToUse > 4 && pageToUse + 2 < pageCount) {
          return setVisiblePages([1, pageToUse - 1, pageToUse, pageToUse + 1, pageCount]);
        } else if (pageToUse % 5 >= 0 && pageToUse > 4 && pageToUse + 2 >= pageCount) {
          return setVisiblePages([1, pageCount - 3, pageCount - 2, pageCount - 1, pageCount]);
        } else {
          return setVisiblePages([1, 2, 3, 4, 5, pageCount]);
        }
      }
    },
    [currentPage]
  );

  useEffect(
    page => {
      getVisiblePages(page, totalPages);
    },
    [getVisiblePages, totalPages]
  );

  const changePage = page => {
    getVisiblePages(page, totalPages);
    setTablePage(page);
    gotoPage(page);
  };

  const getNextPage = page => {
    setTablePage(page + 1);
    getVisiblePages(page + 1, totalPages);
    nextPage();
  };

  const getPreviousPage = page => {
    setTablePage(page - 1);
    getVisiblePages(page - 1, totalPages);
    previousPage();
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: 110,
      borderBottom: '1px dotted #555',
      padding: 5
    }),
    container: (_, { selectProps: { width } }) => ({
      width: 75
    })
  };

  let endData = currentPage * tableSize;
  if (currentPage === totalPages) {
    endData = totalCount;
  }

  return (
    <div className="pagination-container">
      <Container>
        <Row>
          <Col className="col-sm-12 col-lg-3 pt-4 text-center">
            <span style={{ color: '#999' }}>
              {currentPage * tableSize - tableSize + 1}-{endData} of {totalCount} items
            </span>
          </Col>
          <Col className="col-sm-6 col-lg-6">
            <Pagination className="mt-3" listClassName="justify-content-center">
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink onClick={() => getPreviousPage(currentPage)}>{'Previous'}</PaginationLink>
              </PaginationItem>
              {visiblePages.map((page, index, array) => {
                return array[index - 1] + 2 < page ? (
                  <React.Fragment key={page}>
                    <PaginationItem disabled>
                      <PaginationLink>&hellip;</PaginationLink>
                    </PaginationItem>
                    <PaginationItem active={currentPage === page}>
                      <PaginationLink onClick={() => changePage(page)}>{page}</PaginationLink>
                    </PaginationItem>
                  </React.Fragment>
                ) : (
                  <PaginationItem active={currentPage === page} key={page}>
                    <PaginationLink onClick={() => changePage(page)}>{page}</PaginationLink>
                  </PaginationItem>
                );
              })}
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink onClick={() => getNextPage(currentPage)}>{'Next'}</PaginationLink>
              </PaginationItem>
            </Pagination>
          </Col>
          <Col className="col-sm-6 col-lg-3 pt-3">
            <div className="table-size-selector">
              <Select
                getOptionLabel={tableSize => tableSize + ' items'}
                getOptionValue={tableSize => tableSize}
                name="tableSize"
                onChange={tableSize => setTableSize(tableSize)}
                options={[5, 10, 20, 30, 40, 50]}
                value={tableSize}
                placeholder={tableSize ? `${tableSize}` : 'Page Size...'}
                styles={customStyles}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TablePaginationManual;
