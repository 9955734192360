import invert from 'lodash/invert';

export enum PaymentType {
  Card = 'card',
  BankAccount = 'ach'
}

export enum CardType {
  Credit = 'credit',
  Debit = 'debit'
}

export const PaymentTypeName = new Map<string, string>([
  [PaymentType.Card, 'Card'],
  [PaymentType.BankAccount, 'Bank Account']
]);

export const CardTypeName = new Map<string, string>([
  [CardType.Credit, 'Credit Card'],
  [CardType.Debit, 'Debit Card']
]);

export const PaymentTypeStripeName = new Map<string, string>([
  [PaymentType.Card, 'card'],
  [PaymentType.BankAccount, 'bank_account']
]);

export const GetCardTypeName = (cardType: string): string => {
  const name = CardTypeName.get(cardType) || '';
  return name.toLowerCase();
}

export default Object.freeze({
  ...PaymentType,
  ...invert(PaymentType),
  ...CardType,
  ...invert(CardType)
});
