import cx from 'classnames';
import Fuse from 'fuse.js';
import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup } from 'reactstrap';

import Icon from 'shared/components/Icon';
import { Title, TitleIcon } from 'shared/components/SectionTitle';

import styles from './ResultsTable.styles';

class ResultsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearching: false,
      query: '',
      matches: []
    };

    this.focusSearchInput = this.focusSearchInput.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { isSearching } = this.state;

    if (isSearching !== prevState.isSearching) {
      if (!isSearching) {
        this.setState({ query: '' });
      } else {
        this.focusSearchInput();
      }
    }
  }

  focusSearchInput() {
    this.searchInput.focus();
  }

  handleSearchChange = e => {
    const { items, searchKeys } = this.props;

    const fuse = new Fuse(items, {
      distance: 100,
      keys: searchKeys,
      location: 0,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      shouldSort: true,
      threshold: 0.2
    });

    const matches = fuse.search(e.target.value);

    this.setState({
      [e.target.name]: e.target.value,
      matches
    });
  };

  toggleSearch = status => {
    this.setState(({ isSearching }) => ({
      isSearching: status || !isSearching
    }));
  };

  renderResults() {
    const {
      items,
      resultsComponent,
      resultsComponentProps: { itemKey, ...restResultsProps }
    } = this.props;
    const { query, matches } = this.state;

    const ResultsComponent = resultsComponent;

    let results = items.slice(0);

    if (query && query !== '') {
      if (matches.length) {
        results = matches;
      } else {
        return (
          <tr>
            <td colSpan="5">No results found for "{query}"</td>
          </tr>
        );
      }
    }

    return results.map(i => <ResultsComponent key={i[itemKey]} {...restResultsProps} {...i} />);
  }

  renderSearchBar() {
    const { isSearching } = this.state;
    const { items, searchKeys } = this.props;

    if (!!items.length && !!searchKeys.length) {
      return (
        <div className="title-search">
          <input
            className={cx('form-control title-search-input', {
              'title-search-input--expanded': isSearching
            })}
            name="query"
            onChange={this.handleSearchChange}
            placeholder="Search&hellip;"
            ref={input => (this.searchInput = input)}
            value={this.state.query}
          />
          <Button className="ml-1" color="light" onClick={this.toggleSearch.bind(null, false)} size="sm">
            {isSearching ? <Icon icon="cancel" /> : <Icon icon="search" />}
          </Button>
        </div>
      );
    }
    return null;
  }

  render() {
    const { actions, title, items, icon, headings, emptyText } = this.props;

    return (
      <div css={styles}>
        <Title className="d-flex align-items-center pr-0 mb-0">
          <TitleIcon icon={icon} />
          {title}
          {this.renderSearchBar()}
          {actions && actions}
        </Title>

        {!!items.length ? (
          <CardGroup>
            <Card>
              <CardBody>
                <table className="table table-flex">
                  <thead>
                    <tr>{headings}</tr>
                  </thead>
                  <tbody>{this.renderResults()}</tbody>
                </table>
              </CardBody>
            </Card>
          </CardGroup>
        ) : (
          <div className="alert alert-info text-center">
            <em>{emptyText}</em>
          </div>
        )}
      </div>
    );
  }
}

export default ResultsTable;
