import styled from 'styled-components';
import sctheme from 'app/styles/sctheme';

const Card = styled.div`
  position: relative;
  width: 100%;
  background: ${sctheme.white};
  padding: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-top: 2px solid ${props => props.theme.primary};
`;

export default Card;
