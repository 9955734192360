import React from 'react';

const Error = ({ children, className, error }) => {
  let message = children;

  if (!children) {
    if (error) {
      if (error.response && error.response.data) {
        if (error.response.data.message) {
          ({ message } = error.response.data.message);
        } else if (error.response.data.errorMessage) {
          message = error.response.data.errorMessage;
        }
      } else if (error.data && error.data.errorMessage) {
        message = error.data.errorMessage;
      } else if (error.request && error.message) {
        ({ message } = error.message);
      } else if (error.data && error.data.error && error.data.error.message) {
        ({ message } = error.data.error.message);
      } else {
        message = 'Something went wrong.';
      }
    }
  }

  if (message) {
    return (
      <div className={className}>
        <div className="alert alert-danger">{message}</div>
      </div>
    );
  }

  return null;
};

Error.defaultProps = {
  className: 'm-3'
};

export default Error;
