import React from 'react';
import { FAIcon } from 'shared/components/FAIcon';
import { faSort, faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';

const Table = props => {
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    stripedRows,
    cellContent,
    getTdProps,
    prepareRow
  } = props;
  return (
    <div className="ReactTable -striped -highlight table">
      <div {...getTableProps()} className="rt-table">
        <div className="rt-thead -header">
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()} className="rt-tr tr">
              {headerGroup.headers.map(column => {
                return (
                  <div
                    {...column.getHeaderProps()}
                    className="rt-th font-weight-bold rt-resizable-header -cursor-pointer th"
                  >
                    <div>
                      {column.canSort ? (
                        <div
                          {...column.getSortByToggleProps()}
                          className="header-content d-flex"
                          style={{ justifyContent: column.flex }}
                        >
                          {column.render('Header')}
                          {column.canSort && (
                            <span className="sort">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <FAIcon icon={faSortDown} />
                                ) : (
                                  <FAIcon icon={faSortUp} />
                                )
                              ) : (
                                <FAIcon icon={faSort} />
                              )}
                            </span>
                          )}
                        </div>
                      ) : (
                        <div className="header-content" style={{ justifyContent: column.flex }}>
                          {column.render('Header')}
                        </div>
                      )}
                      {column.canResize && <div {...column.getResizerProps()} />}
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div className="rt-tbody">
          <div {...getTableBodyProps()} className="rt-tr-group">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  <div {...row.getRowProps()} className={stripedRows(i)}>
                    {row.cells.map(cell => {
                      return (
                        <div {...cell.getCellProps()} {...getTdProps(cell)} className={cellContent(cell)}>
                          {cell.render('Cell')}
                        </div>
                      );
                    })}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
