import { withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Form, Label, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Icon from 'shared/components/Icon';

import styles from './SelectBatchForm.styles';

let SelectBatchFormNew = ({ billing, handleSubmit, onSubmit, selectedBilling, setFieldValue, billingDeleteAllPreflight, billingDeleteAllCustomers, recentlyDeleted }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [whichBatch, setWhichBatch] = useState({});

  useEffect(() => {
    setFieldValue('selectedBilling', selectedBilling);
  }, [selectedBilling, setFieldValue]);

  if (!billing.length) return null;

  const openModal = (batch) => {
    setIsOpen(true);
    setWhichBatch(batch);
  };

  const closeModal = () => {
    setIsOpen(false);
    setWhichBatch({});
  };

  const preflightBatch = () => {
    billingDeleteAllPreflight(whichBatch.id);
    closeModal();
  };

  const deleteBatch = () => {
    if (!whichBatch.isFinalized) {
      billingDeleteAllCustomers(whichBatch.id);
    }
    closeModal();
  };

  const showDeleteButton = (batch) => {
    return !(batch.isFinalized || recentlyDeleted.includes(batch.id))
  };

  return (
    <div css={styles}>
      <hr />
      <div className="pl-4 pr-4">
        <h5>
          <strong>Batch</strong>
        </h5>

        <div className="select-batch-form mb-2">
          <Form autoComplete="nope" onSubmit={handleSubmit}>
            {billing.filter(b => !recentlyDeleted.includes(b.id)).map(batch => {
              const description = batch.createdBy
                ? `Created on ${batch.createdAt} by ${batch.createdBy}`
                : `Created on ${batch.createdAt}`;
              return (
                <div className="batch-list-item">
                  <div className="form-check mb-2" key={batch.id}>
                    <Input
                      checked={batch.id === selectedBilling}
                      className="form-check-input"
                      id={`selectedBilling-${batch.id}`}
                      name="selectedBilling"
                      onChange={() => onSubmit(batch.id)}
                      placeholder="Selected Batch"
                      type="radio"
                    />
                    <Label className="form-check-label" style={{ fontWeight: '600' }} for={`selectedBilling-${batch.id}`}>
                      {batch.name}
                    </Label>
                    <p className="text-truncate" style={{ fontSize: '14px', color: '#747377' }}>
                      {description}
                    </p>
                  </div>
                  {showDeleteButton(batch) && (
                    <div onClick={() => openModal(batch)}>
                      <Icon className="trash-can" icon="delete" />
                    </div>
                  )}
                </div>
              );
            })}
          </Form>
        </div>
      </div>

      <Modal isOpen={isOpen}>
        <ModalHeader>Delete batch {whichBatch.name}?</ModalHeader>
        <ModalBody>All non-finalized invoices in the batch will be deleted.</ModalBody>
        <ModalFooter>
          <Button color="link" onClick={closeModal}>Cancel</Button>
          <Button onClick={preflightBatch}>No, Just Run Preflight Check</Button>
          <Button onClick={deleteBatch}>Yes, Delete</Button>
        </ModalFooter>
      </Modal>

    </div>
  );
};

SelectBatchFormNew = withFormik({
  mapPropsToValues: ({ selectedBilling }) => ({
    selectedBilling
  })
})(SelectBatchFormNew);

export default SelectBatchFormNew;
