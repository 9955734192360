import { createSelector } from 'reselect';

import { getCreditBandsById } from 'shared/store/modules/creditBands/selectors';

export function getOffersById(state) {
  return state.admin.offers;
}

export const getOffer = createSelector(
  [getOffersById, (_, props) => props.match.params.offerId],
  (offers, offerId) => offers.byId[offerId] || {}
);

export const getOfferCreditBands = createSelector([getOffer, getCreditBandsById], (offer, creditBands) => {
  if (offer && offer.creditBands) {
    return offer.creditBands.map(id => creditBands.byId[id]).filter(creditBand => !creditBand.deleted);
  }

  return [];
});
