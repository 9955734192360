import { normalize } from 'normalizr';

import { addEntities, deleteEntities } from 'shared/store/modules/entities';
import {
  createUtilityRequest,
  createUtilityResponse,
  fetchAllUtilitiesRequest,
  fetchAllUtilitiesResponse,
  fetchUtilityDetailsRequest,
  fetchUtilityDetailsResponse,
  fetchUtilityRequest,
  fetchUtilityResponse,
  updateUtilityRequest,
  updateUtilityResponse
} from 'shared/store/modules/utilities/actions';
import toast from 'shared/utilities/toast';

export const fetchUtilities = () => async (dispatch, _getState, { api, schemas }) => {
  dispatch(fetchAllUtilitiesRequest());

  try {
    let { data } = await api.utility.fetchAll();
    data = normalize(data, schemas.utilities);

    dispatch(addEntities(data));
    return dispatch(fetchAllUtilitiesResponse(data));
  } catch (err) {
    return dispatch(fetchAllUtilitiesResponse(err));
  }
};

export const fetchUtility = id => async (dispatch, _getState, { api, schemas }) => {
  dispatch(fetchUtilityRequest());

  try {
    let { data } = await api.utility.fetchOne(id);
    data = normalize(data, schemas.utility);

    dispatch(addEntities(data));
    return dispatch(fetchUtilityResponse(data));
  } catch (err) {
    return dispatch(fetchUtilityResponse(err));
  }
};

export const fetchUtilityDetails = id => async (dispatch, _getState, { api, schemas }) => {
  dispatch(fetchUtilityDetailsRequest());

  try {
    let { data } = await api.utility.fetchDetails(id);
    data = normalize(data, schemas.utility);

    dispatch(addEntities(data));
    return dispatch(fetchUtilityDetailsResponse(data));
  } catch (err) {
    return dispatch(fetchUtilityDetailsResponse(err));
  }
};

export const createUtility = utility => async (dispatch, _getState, { api, schemas }) => {
  dispatch(createUtilityRequest());

  try {
    let { data } = await api.utility.create(utility);
    data = normalize({ ...data, ...utility }, schemas.utility);

    toast.success('New utility created');
    dispatch(addEntities(data));
    return dispatch(createUtilityResponse(data));
  } catch (err) {
    toast.error(err);
    return dispatch(createUtilityResponse(err));
  }
};

export const updateUtility = (id, updates) => async (dispatch, _getState, { api, schemas }) => {
  dispatch(updateUtilityRequest());

  try {
    await api.utility.update(id, updates);
    const data = normalize({ id, ...updates }, schemas.utility);

    toast.success('Utility updated');
    dispatch(addEntities(data));
    return dispatch(updateUtilityResponse(data));
  } catch (err) {
    toast.error(err);
    return dispatch(updateUtilityResponse(err));
  }
};

export const deleteUtility = id => async (dispatch, _getState, { api }) => {
  dispatch(updateUtilityRequest());

  try {
    await api.utility.delete(id);
    toast.success('Utility deleted');
    return dispatch(deleteEntities({ id }));
  } catch (err) {
    toast.error(err);
    return dispatch(updateUtilityResponse(err));
  }
};

export const fetchAssociatedUtilities = utilityId => async (_dispatch, _getState, { api }) => {
  try {
    const { data } = await api.utility.fetchAssociatedUtilities(utilityId);
    return data;
  } catch (err) {
    return {
      error: true
    };
  }
};

export const updateAssociatedUtilities = (utilityId, associatedUtilityIds) => async (_dispatch, _getState, { api }) => {
  try {
    const { data } = await api.utility.updateAssociatedUtilities(utilityId, associatedUtilityIds);
    return data;
  } catch (err) {
    return {
      error: true
    };
  }
};
