import { normalize } from 'normalizr';
import { selectAccountId } from 'shared/store/modules/accounts/selectors';

import { addEntities, deleteEntities } from 'shared/store/modules/entities';
import {
  createSubscriberUserRequest,
  createSubscriberUserResponse,
  exportSubscriberActivitiesRequest,
  exportSubscriberActivitiesResponse,
  fetchAllSubscribersRequest,
  fetchAllSubscribersResponse,
  fetchSubscribersByUtilityRequest,
  fetchSubscribersByUtilityResponse,
  fetchAutoAllocatableCustomersRequest,
  fetchAutoAllocatableCustomersResponse,
  fetchSubscriberDetailsRequest,
  fetchSubscriberDetailsResponse,
  fetchSubscriberInvoiceRequest,
  fetchSubscriberInvoiceResponse,
  fetchSubscriberInvoiceByDocIdRequest,
  fetchSubscriberInvoiceByDocIdResponse,
  fetchSubscriberRequest,
  fetchSubscriberResponse,
  updateSubscriberRequest,
  updateSubscriberResponse
} from 'shared/store/modules/subscribers/actions';
import toast from 'shared/utilities/toast';

export const fetchInvoice = invoiceId => async (dispatch, _getState, { api }) => {
  dispatch(fetchSubscriberInvoiceRequest());

  try {
    const { data } = await api.subscriber.getCustomerInvoice(invoiceId);

    return dispatch(fetchSubscriberInvoiceResponse(data));
  } catch (err) {
    toast.error(err);
    return dispatch(fetchSubscriberInvoiceResponse(err));
  }
};

export const fetchInvoiceByDocId = documentId => async (dispatch, _getState, { api }) => {
  dispatch(fetchSubscriberInvoiceRequest());

  try {
    const { data } = await api.subscriber.getCustomerInvoiceByDocId(documentId);

    return dispatch(fetchSubscriberInvoiceByDocIdRequest(data));
  } catch (err) {
    toast.error(err);
    return dispatch(fetchSubscriberInvoiceByDocIdResponse(err));
  }
};

export const fetchCustomerInvoices = () => async (dispatch, _getState, { api }) => {
  dispatch(fetchSubscriberInvoiceRequest());

  try {
    const customerId = selectAccountId(_getState());
    const { data } = await api.subscriber.getCustomerInvoices(customerId);

    return dispatch(fetchSubscriberInvoiceResponse(data));
  } catch (err) {
    return dispatch(fetchSubscriberInvoiceResponse(err));
  }
};

export const exportActivities = id => async (dispatch, _getState, { api }) => {
  dispatch(exportSubscriberActivitiesRequest());

  try {
    const { data } = await api.subscriber.exportActivities(id);
    return dispatch(exportSubscriberActivitiesResponse(data));
  } catch (err) {
    return dispatch(exportSubscriberActivitiesResponse(err));
  }
};

export const fetchSubscriberDetails = (id, portfolioId) => async (dispatch, _getState, { api, schemas }) => {
  dispatch(fetchSubscriberDetailsRequest());

  try {
    const { data } = await api.subscriber.fetchDetails(id, portfolioId);
    const subscriber = normalize(data, schemas.subscriber);

    dispatch(addEntities(subscriber));
    dispatch(fetchSubscriberDetailsResponse(subscriber));
  } catch (err) {
    dispatch(fetchSubscriberDetailsResponse(err));
  }
};

export const fetchSubscribers = params => async (dispatch, _getState, { api, schemas }) => {
  dispatch(fetchAllSubscribersRequest());

  try {
    let { data } = await api.subscriber.fetchAll(params);
    data = normalize(data, schemas.subscribers);
    dispatch(addEntities(data));
    dispatch(fetchAllSubscribersResponse(data));
  } catch (err) {
    return dispatch(fetchAllSubscribersResponse(err));
  }
};

export const fetchSimplifiedSubscribers = params => async (dispatch, _getState, { api, schemas }) => {
  dispatch(fetchAllSubscribersRequest());

  try {
    let { data } = await api.subscriber.fetchAllSimplified(params);
    data = normalize(data, schemas.subscribers);
    dispatch(addEntities(data));
    dispatch(fetchAllSubscribersResponse(data));
  } catch (err) {
    return dispatch(fetchAllSubscribersResponse(err));
  }
};

export const fetchSubscribersByUtility = utilityId => async (dispatch, _getState, { api }) => {
  dispatch(fetchSubscribersByUtilityRequest());

  try {
    let data = await api.subscriber.fetchAllByUtility(utilityId);

    return dispatch(fetchSubscribersByUtilityResponse(data));
  } catch (err) {
    return dispatch(fetchSubscribersByUtilityResponse(err));
  }
};

export const fetchAutoAllocatableCustomers = (utilityId, effectiveDate) => async (dispatch, _getState, { api }) => {
  dispatch(fetchAutoAllocatableCustomersRequest());

  try {
    let data = await api.subscriber.fetchAutoAllocatableCustomers(utilityId, effectiveDate);

    return dispatch(fetchAutoAllocatableCustomersResponse(data));
  } catch (err) {
    return dispatch(fetchAutoAllocatableCustomersResponse(err));
  }
};

export const fetchSubscriber = id => async (dispatch, _getState, { api, schemas }) => {
  dispatch(fetchSubscriberRequest());

  try {
    let { data } = await api.subscriber.fetchOne(id);
    data = normalize(data, schemas.subscriber);

    dispatch(addEntities(data));
    dispatch(fetchSubscriberResponse(data));
  } catch (err) {
    return dispatch(fetchSubscriberResponse(err));
  }
};

export const createSubscriberUser = subscriber => async (dispatch, _getState, { api, schemas }) => {
  dispatch(createSubscriberUserRequest());

  try {
    const { data } = await api.subscriber.createUser(subscriber);
    toast.success('New subscriber user created');
    return dispatch(createSubscriberUserResponse({ ...data, ...subscriber }));
  } catch (err) {
    toast.error(err);
    return dispatch(createSubscriberUserResponse(err));
  }
};

export const updateSubscriber = (id, updates) => async (dispatch, _getState, { api, schemas }) => {
  dispatch(updateSubscriberRequest());

  try {
    await api.subscriber.update(id, updates);
    let data = { id, ...updates };
    data = normalize(data, schemas.subscriber);
    toast.success('Subscriber updated');
    dispatch(addEntities(data));
    return dispatch(fetchSubscriberResponse(data));
  } catch (err) {
    toast.error(err);
    return dispatch(updateSubscriberResponse(err));
  }
};

export const deleteSubscriber = id => async (dispatch, _getState, { api, schemas }) => {
  dispatch(updateSubscriberRequest());
  try {
    await api.subscriber.deleteCustomer(id);
    toast.success('Subscriber deleted');
    dispatch(deleteEntities({ id }));
  } catch (err) {
    toast.error(err);
    return dispatch(updateSubscriberResponse(err));
  }
};
