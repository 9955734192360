import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';

import { auth0Login } from 'shared/store/modules/auth/effects';
import { fetchAccounts } from 'shared/store/modules/accounts/effects';
import { fetchProspect, checkInquiryCapacity } from 'shared/store/modules/prospects/effects';
import { setActiveAccountId } from 'shared/store/modules/accounts/actions';
import { getProviderHostname } from 'shared/store/modules/provider/selectors';

import { useAuth0 } from '@auth0/auth0-react';
import { useLogout } from './useLogout';

export function useAuth0Session() {
  const dispatch = useAppDispatch();
  const [isRenewing, setIsRenewing] = useState(false);
  const history = useHistory();
  const hostname = useSelector(getProviderHostname);
  const { user, getAccessTokenSilently, isLoading, isAuthenticated, error } = useAuth0();
  const logout = useLogout();

  const isAdmin = useCallback(() => {
    if (!isAuthenticated) return false;
    return (
      user &&
      user['http://schemas.suncentral.net/claims/roles']
        ?.join(' ')
        .toLowerCase()
        .includes('admin')
    );
  }, [isAuthenticated, user]);

  const getRole = useCallback(() => {
    if (!isAuthenticated) return null;
    return user['http://schemas.suncentral.net/claims/roles'][0];
  }, [isAuthenticated, user]);

  const renewSession = useCallback(
    (sessionCallback, cacheMode = 'off') => {
      setIsRenewing(true);
      if (isAuthenticated) {
        try {
          getAccessTokenSilently({
            cacheMode: cacheMode,
            detailedResponse: true
          })
            .then(response => {
              const expires = new Date();
              expires.setSeconds(expires.getSeconds() + response.expires_in);

              dispatch(
                auth0Login(
                  {
                    ...response,
                    role: getRole(),
                    hostname: hostname,
                    expires_in: response.expires_in,
                    expires: expires
                  },
                  user.sub
                )
              );

              if (sessionCallback) sessionCallback(response);
            })
            .catch(console.error)
            .finally(() => setIsRenewing(false));
        } catch (error) {
          console.error('Error caught in renewSession', error);
        } finally {
          setIsRenewing(false);
        }
      }
    },
    [getAccessTokenSilently, dispatch, user, hostname, isAuthenticated, getRole]
  );

  const startSession = useCallback(
    callback => {
      renewSession(async () => {
        if (!isAdmin()) {
          try {
            const accounts = await dispatch(fetchAccounts());

            if (accounts.length === 1) {
              dispatch(setActiveAccountId(accounts[0].id));
            }

            if (callback) callback(accounts);
          } catch (e) {
            return logout(
              {},
              `/login?error=${encodeURIComponent(e.error)}&error_type=${getRole() ? 'no_accounts' : 'no_role'}`
            );
          }
        }
      }, 'off');
    },
    [renewSession, dispatch, isAdmin, logout, getRole]
  );

  const automaticDispatchToRoute = useCallback(() => {
    renewSession(async () => {
      if (!isAdmin()) {
        try {
          const accounts = await dispatch(fetchAccounts());
          if (accounts.length === 1) {
            dispatch(setActiveAccountId(accounts[0].id));
            if (
              accounts[0].isStreamlinedProspect &&
              accounts[0].nextStepToComplete &&
              accounts[0].nextStepToComplete === 600
            ) {
              const prospect = await dispatch(fetchProspect(accounts[0].id));

              if (prospect) {
                const { associatedUtilityId, stateId } = prospect.payload;
                await dispatch(checkInquiryCapacity(null, associatedUtilityId, stateId))
                  .then(data => {
                    if (data?.payload?.data) {
                      const { programsAndCapacityAvailable } = data.payload.data;
                      if (!programsAndCapacityAvailable) {
                        history.push('/signup/review?addToWaitlist=true');
                      }
                    }
                  })
                  .catch(error => {
                    console.error('Error caught in automaticDispatchToRoute', error);
                  });
              }
            }
          } else {
            history.push('/accounts');
          }
        } catch (e) {
          return logout(
            {},
            `/login?error=${encodeURIComponent(e.error)}&error_type=${getRole() ? 'no_accounts' : 'no_role'}`
          );
        }
      }

      history.push('/');
    });
  }, [dispatch, isAdmin, history, renewSession, logout, getRole]);

  return {
    automaticDispatchToRoute,
    startSession,
    renewSession,
    user,
    error,
    isLoading,
    isAuthenticated,
    isAdmin,
    getRole,
    isRenewing
  };
}
