import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

import { getActivitiesById } from 'shared/store/modules/activities/selectors';
import { getSettlementAccountsById } from 'shared/store/modules/settlementAccounts/selectors';
import { getUsersById } from 'shared/store/modules/users/selectors';

// TODO Duplicated this to prevent circular dependency
function getProjectsById(state) {
  return state.admin.projects;
}

export function selectPortfoliosStatus(state) {
  return state.admin.portfolios.status;
}

export function getPortfoliosById(state) {
  return state.admin.portfolios;
}

export const getPortfolios = createSelector([getPortfoliosById], portfolios => {
  return orderBy(
    portfolios.allIds.map(id => portfolios.byId[id]),
    ['name']
  );
});

export const selectPortfolio = portfolioId => state => {
  const portfolio = state.admin.portfolios.byId[portfolioId];

  return portfolio || {};
};

export function getPortfolio(state, portfolioId) {
  const portfolio = state.admin.portfolios.byId[portfolioId];

  return portfolio || {};
}

export const getPortfolioProjects = createSelector([getPortfolio, getProjectsById], (portfolio, projects) => {
  if (portfolio && portfolio.projects) {
    return orderBy(
      portfolio.projects.map(id => projects.byId[id]),
      ['name']
    );
  }

  return [];
});

export const getPortfolioUsers = createSelector([getPortfolio, getUsersById], (portfolio, users) => {
  if (portfolio && portfolio.users) {
    return portfolio.users.map(id => users.byId[id]);
  }

  return [];
});

export const getPortfolioActivities = createSelector([getPortfolio, getActivitiesById], (portfolio, activities) => {
  let payload = [];

  if (portfolio && portfolio.activities) {
    payload = portfolio.activities.map(id => activities.byId[id]).filter(n => n);
  }

  return orderBy(payload, ['effectiveDate'], ['desc']);
});

export const getPortfolioSettlementAccount = createSelector(
  [getPortfolio, getSettlementAccountsById],
  (portfolio, settlementAccounts) => {
    if (portfolio && portfolio.settlementAccount) {
      return settlementAccounts[portfolio.settlementAccount];
    }

    return {};
  }
);
