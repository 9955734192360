import download from 'downloadjs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';

import Error from 'shared/components/Error';
import { LoadingCard } from 'shared/components/Loading';
import { SubscriberType } from 'shared/models/Subscriber/constants';
import {
  billingDeleteCustomer,
  invoiceGenerationStatus,
  generatePreflightExport,
  clearPreflightData
} from 'shared/store/modules/invoices/effects';
import { fetchInvoice } from 'shared/store/modules/subscribers/effects';

import { withInvoiceContext } from './InvoicesContext';
import InvoicesLayout from './InvoicesLayout';
import InvoiceResultsTableRow from './components/InvoiceResultsTableRow';
import InvoicesStatus from './components/InvoicesStatus/InvoicesStatus';
import PreflightModal from './components/PreflightModal';
import ResultsTable from './components/ResultsTable';
import { POLLING_INTERVAL } from './constants';

class InvoicesShow extends Component {
  static displayName = 'InvoicesShowView';
  static defaultProps = {
    invoiceGenerationDetails: []
  };

  constructor(props) {
    super(props);

    this.intervalId = null;

    this.startPoll = this.startPoll.bind(this);
    this.clearPoll = this.clearPoll.bind(this);
  }

  componentDidMount() {
    this.handleCheckStatus();
  }

  componentDidUpdate(prevProps) {
    const { isProcessing } = this.props;
    if (isProcessing !== prevProps.isProcessing) {
      if (!isProcessing) {
        this.clearPoll();
      }

      if (isProcessing) {
        this.startPoll();
      }
    }

    if (prevProps.billingId !== this.props.billingId) {
      this.handleCheckStatus();
    }
  }

  componentWillUnmount() {
    this.clearPoll();
  }

  UNSAFE_componentWillReceiveProps({ month, year, invoiceStatusExportData, preflightExportData }) {
    if (invoiceStatusExportData !== this.props.invoiceStatusExportData && invoiceStatusExportData) {
      download(invoiceStatusExportData, `${month}-${year}_Statement-Status-Export.csv`, 'text/csv');
    }

    if (preflightExportData !== this.props.preflightExportData && preflightExportData) {
      download(preflightExportData, `${month}-${year}_Preflight-Export.csv`, 'text/csv');
    }
  }

  startPoll() {
    this.intervalId = setInterval(this.handleCheckStatus, POLLING_INTERVAL);
  }

  clearPoll() {
    return clearInterval(this.intervalId);
  }

  handleCheckStatus = () => {
    const { actions, billingId } = this.props;
    actions.invoiceGenerationStatus(billingId);
  };

  handleDownloadPreflightExport = () => {
    const { actions, billingId } = this.props;
    actions.generatePreflightExport(billingId);
  };

  isFinalized() {
    return this.props.queuedCount === 0;
  }

  isReadyToGenerate() {
    // const { error } = this.props;

    return true;

    // TODO: Adjust API for better response shape
    // to determine whether we have run a generation yet?
    // const errorMessage = error && error.data ? error.data.errorMessage : '';
    // return errorMessage.indexOf('No billing found') > -1;
  }

  renderResults = () => {
    const { month, year, isFetching, fetchInvoice, data } = this.props;
    if (isFetching) return null;

    const { invoiceGenerationDetails: batches } = data;

    if (!!batches.length) {
      const commercialItems = batches.filter(batch => batch.customerType === SubscriberType.Commercial);
      const residentialItems = batches.filter(batch => batch.customerType === SubscriberType.Residential);

      const headings = (
        <>
          <th className="col-3">Customer Name</th>
          <th className="col-2 text-center">Created</th>
          <th className="col-2 text-center">Journaled</th>
          <th className="col-2 text-center">Enabled</th>
          <th className="col-2 text-center">Notified</th>
          <th className="col-1">&nbsp;</th>
        </>
      );

      const resultsComponentProps = {
        month,
        year,
        deleteCustomer: this.props.actions.billingDeleteCustomer,
        fetchInvoice,
        itemKey: 'billingCustomerDetailId'
      };

      const searchKeys = ['displayName'];

      return (
        <>
          <InvoicesStatus {...data} />
          <ResultsTable
            emptyText="No commerical subscribers included in this batch"
            headings={headings}
            icon="business"
            items={commercialItems}
            resultsComponent={InvoiceResultsTableRow}
            resultsComponentProps={resultsComponentProps}
            searchKeys={searchKeys}
            title="Commercial Subscribers"
          />
          <ResultsTable
            emptyText="No residential subscribers included in this batch"
            headings={headings}
            icon="home"
            items={residentialItems}
            resultsComponent={InvoiceResultsTableRow}
            resultsComponentProps={resultsComponentProps}
            searchKeys={searchKeys}
            title="Residential Subscribers"
          />
        </>
      );
    }

    return null;
  };

  render() {
    const { actions, isFetching, error, month, year, preflightCheck } = this.props;

    if (isFetching) return <LoadingCard className="mb-3" />;

    return (
      <>
        {preflightCheck && (
          <PreflightModal
            month={month}
            onClearPreflight={actions.clearPreflightData}
            onDownloadPreflight={this.handleDownloadPreflightExport}
            preflightCheck={preflightCheck}
            year={year}
          />
        )}
        {this.renderResults()}
        {error && !this.isReadyToGenerate() && <Error className="mt-3 mb-3" error={error} />}
      </>
    );
  }
}

const mapStateToProps = state => ({
  data: state.invoices.data,
  error: state.invoices.error,
  invoiceStatusExportData: state.invoices.invoiceStatusExportData,
  isDeleting: state.invoices.isDeleting,
  isFetching: state.invoices.isFetching,
  isProcessing: state.invoices.data.isProcessing,
  preflightCheck: state.invoices.preflightCheck,
  preflightExportData: state.invoices.preflightExportData
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchInvoice(id) {
    return dispatch(fetchInvoice(id));
  },
  actions: bindActionCreators(
    {
      billingDeleteCustomer,
      invoiceGenerationStatus,
      generatePreflightExport,
      clearPreflightData
    },
    dispatch
  )
});

const ConnectedView = compose(withInvoiceContext, connect(mapStateToProps, mapDispatchToProps))(InvoicesShow);

export const InvoicesShowView = ({ error }) => (
  <InvoicesLayout error={error}>
    <ConnectedView />
  </InvoicesLayout>
);

export default InvoicesShowView;
