import {
  clearPreflight,
  createFromFilters,
  createRemaining,
  createWithCustomers,
  deleteCustomerFromBilling,
  deleteAllCustomersFromBilling,
  deleteAllCustomersFromBillingPreflight,
  deleteInvoices,
  fetchingInvoices,
  finalizeInvoices,
  generateInvoicesStatusExport,
  getBilling,
  getBillingDates,
  getBillingDatesError,
  getEstimate,
  invoicesBillingPreflightCheck,
  invoicesError,
  invoicesGenerateInvoices,
  invoicesGeneratePreflightExport,
  invoicesActivityStart,
  invoicesActivityEnd,
  invoicesGeneratingExportStart,
  isGettingBillingDates
} from 'shared/store/modules/invoices/actions';
import toast from 'shared/utilities/toast';

export const fetchYears = () => async (dispatch, _getState, { api }) => {
  dispatch(isGettingBillingDates());
  try {
    const { data } = await api.billing.getBillingDates();
    return dispatch(getBillingDates(data));
  } catch (err) {
    toast.error(err);
    return dispatch(getBillingDatesError(err));
  }
};

export const runPreflightCheck = billingId => async (dispatch, _getState, { api }) => {
  try {
    const { data } = await api.billing.checkPreflight(billingId);
    return dispatch(invoicesBillingPreflightCheck(data));
  } catch (err) {
    toast.error(err);
    return dispatch(invoicesError(err));
  }
};

export const generatePreflightExport = billingId => async (dispatch, _getState, { api }) => {
  dispatch(invoicesGeneratingExportStart());

  try {
    const { data } = await api.billing.getPreflightExport(billingId);
    return dispatch(invoicesGeneratePreflightExport(data));
  } catch (err) {
    toast.error(err);
    return dispatch(invoicesError(err));
  }
};

// TODO: could DRY up this code a little bit possibly?
export const generateInvoices = (billingId, callback) => async (dispatch, _getState, { api }) => {
  dispatch(invoicesActivityStart());

  try {
    const { data } = await api.billing.generateInvoices(billingId);

    const { queuedToGenerateInvoiceCount } = data;
    const statusMessage =
      queuedToGenerateInvoiceCount > 1
        ? `Generating ${queuedToGenerateInvoiceCount} statements`
        : `Generating ${queuedToGenerateInvoiceCount} statement`;
    toast.success(statusMessage);

    if (queuedToGenerateInvoiceCount > 0) {
      return dispatch(invoicesGenerateInvoices(data, statusMessage));
    } else {
      return dispatch(invoicesActivityEnd(data));
    }
  } catch (err) {
    toast.error(err);
    return dispatch(invoicesError(err));
  }
};

export const invoiceGenerationStatus = billingId => async (dispatch, _getState, { api }) => {
  dispatch(invoicesActivityStart());

  try {
    const {
      data,
      data: { isProcessing }
    } = await api.billing.invoiceGenerationStatus(billingId);

    if (!isProcessing) {
      return dispatch(invoicesActivityEnd(data));
    } else {
      return dispatch(invoicesGenerateInvoices(data));
    }
  } catch (err) {
    toast.error(err);
    return dispatch(invoicesError(err));
  }
};

export const invoiceGenerateStatusExport = billingId => async (dispatch, _getState, { api }) => {
  dispatch(invoicesGeneratingExportStart());

  try {
    const { data } = await api.billing.invoiceGenerateStatusExport(billingId);
    return dispatch(generateInvoicesStatusExport(data));
  } catch (err) {
    toast.error(err);
    return dispatch(invoicesError(err));
  }
};

export const deleteInvoice = (billingId, callback) => async (dispatch, _getState, { api }) => {
  dispatch(invoicesActivityStart());

  try {
    const { data } = await api.billing.abortBilling(billingId);
    const { queuedToAbortInvoiceCount } = data;
    const statusMessage =
      queuedToAbortInvoiceCount > 1
        ? `Deleted ${queuedToAbortInvoiceCount} statements`
        : `Deleted ${queuedToAbortInvoiceCount} statement`;
    toast.success(statusMessage);

    return dispatch(deleteInvoices(data, statusMessage));
  } catch (err) {
    toast.error(err);
    return dispatch(invoicesError(err));
  }
};

export const invoiceFinalize = (billingId, callback) => async (dispatch, _getState, { api }) => {
  dispatch(invoicesActivityStart());

  try {
    const { data } = await api.billing.finalizeBilling(billingId);
    const { queuedToFinalizeInvoiceCount } = data;
    const statusMessage =
      queuedToFinalizeInvoiceCount > 1
        ? `Finalizing ${queuedToFinalizeInvoiceCount} statements`
        : `Finalizing ${queuedToFinalizeInvoiceCount} statement`;
    toast.success(statusMessage);

    if (queuedToFinalizeInvoiceCount > 0) {
      return dispatch(finalizeInvoices(data, statusMessage));
    } else {
      return dispatch(invoicesActivityEnd(data));
    }
  } catch (err) {
    toast.error(err);
    return dispatch(invoicesError(err));
  }
};

export const getRemainingCustomersEstimate = ({ month, year }) => async (dispatch, _getState, { api }) => {
  try {
    const { data } = await api.billing.createRemainingCustomersEstimate({
      month,
      year
    });
    return dispatch(getEstimate(data));
  } catch (err) {
    toast.error(err);
    return dispatch(invoicesError(err));
  }
};

export const createRemainingCustomers = ({ month, year }) => async (dispatch, _getState, { api }) => {
  try {
    const { data } = await api.billing.createRemainingCustomers({
      forMonth: month,
      forYear: year
    });
    return dispatch(createRemaining(data));
  } catch (err) {
    toast.error(err);
    return dispatch(invoicesError(err));
  }
};

export const getBillingByDate = ({ year, month }) => async (dispatch, _getState, { api }) => {
  dispatch(fetchingInvoices());
  try {
    const { data } = await api.billing.getBilling({ year, month });
    return dispatch(getBilling(data));
  } catch (err) {
    toast.error(err);
    return dispatch(invoicesError(err));
  }
};

export const createBillingFromFilters = payload => async (dispatch, _getState, { api }) => {
  dispatch(fetchingInvoices());
  try {
    const { data } = await api.billing.createFromFilters(payload);
    const { forYear: year, forMonth: month, name } = payload;
    toast.success(`Batch named "${name}" created for ${month}, ${year}!`);
    dispatch(getBillingByDate({ year, month }));
    return dispatch(createFromFilters(data));
  } catch (err) {
    if (err.message.includes('404')) {
      toast.error(err, 'Batch not created: No subscribers found');
    } else {
      toast.error(err);
    }
    return dispatch(invoicesError(err));
  }
};

export const createBillingWithCustomers = payload => async (dispatch, _getState, { api }) => {
  dispatch(fetchingInvoices());
  try {
    const { data } = await api.billing.createWithCustomers(payload);

    const { forMonth, forYear, name } = payload;

    toast.success(`Batch named "${name}" created for ${forMonth}, ${forYear}!`);
    dispatch(getBillingByDate({ year: forYear, month: forMonth }));
    return dispatch(createWithCustomers(data));
  } catch (err) {
    toast.error(err);
    return dispatch(invoicesError(err));
  }
};

export const billingDeleteCustomer = billingCustomerDetailId => async (dispatch, _getState, { api }) => {
  dispatch(fetchingInvoices());
  try {
    await api.billing.deleteCustomerFromBilling(billingCustomerDetailId);

    toast.success(`Customer ${billingCustomerDetailId} deleted from billing!`);
    return dispatch(deleteCustomerFromBilling(billingCustomerDetailId));
  } catch (err) {
    toast.error(err);
    return dispatch(invoicesError(err));
  }
};

export const billingDeleteAllCustomers = billingId => async (dispatch, _getState, { api }) => {
  dispatch(fetchingInvoices());
  try {
    await api.billing.deleteAllCustomersFromBilling(billingId);

    toast.success(`All customers in ${billingId} are queued to be deleted from billing!`);
    return dispatch(deleteAllCustomersFromBilling(billingId));
  } catch (err) {
    toast.error(err);
    return dispatch(invoicesError(err));
  }
};

export const billingDeleteAllPreflight = billingId => async (dispatch, _getState, { api }) => {
  dispatch(fetchingInvoices());
  dispatch(clearPreflight());
  try {
    const { data } = await api.billing.deleteAllCustomersFromBillingPreflight(billingId);

    return dispatch(deleteAllCustomersFromBillingPreflight(data));
  } catch (err) {
    toast.error(err);
    return dispatch(invoicesError(err));
  }
};

export const clearPreflightData = () => (dispatch, _getState, { api }) => {
  dispatch(clearPreflight());
};
