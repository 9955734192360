import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Progress } from 'reactstrap';

import Card from 'shared/components/Card/Card';
import styles from './InvoicesStatus.styles';

const StatusItem = ({ children, amount }) => {
  return (
    <div className="w-25 status-item">
      {children}
      <h2 style={{ marginBottom: 0 }}>{amount}</h2>
    </div>
  );
};

class InvoicesStatus extends Component {
  render() {
    const {
      isInvoicingBusy,
      name,
      residentialCount,
      commercialCount,
      successfullySentCount,
      successfullyGeneratedInvoiceCount
    } = this.props;

    return (
      <div css={styles}>
        {isInvoicingBusy && (
          <Row>
            <Col className="mb-3">
              <Progress
                animated
                color="success"
                style={{
                  height: '30px',
                  fontSize: '18px',
                  fontWeight: '700'
                }}
                value={100}
              >
                In Progress
              </Progress>
            </Col>
          </Row>
        )}
        <Row className="status">
          <Col md={3}>
            <em>Batch</em>
            <h5>
              <strong>{name}</strong>
            </h5>
          </Col>
          <Col md={9}>
            <Card
              className="d-flex"
              style={{
                padding: '10px 20px'
              }}
            >
              <StatusItem amount={successfullyGeneratedInvoiceCount}>
                <em>
                  Statements
                  <br />
                  Generated
                </em>
              </StatusItem>
              <StatusItem amount={successfullySentCount}>
                <em>
                  Statements
                  <br />
                  Sent
                </em>
              </StatusItem>
              <StatusItem amount={residentialCount}>
                <em>
                  Residential
                  <br />
                  Subscribers
                </em>
              </StatusItem>
              <StatusItem amount={commercialCount}>
                <em>
                  Commercial
                  <br />
                  Subscribers
                </em>
              </StatusItem>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isInvoicingBusy: state.invoices.isInvoicingBusy
});

export default connect(mapStateToProps)(InvoicesStatus);
