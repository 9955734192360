import { createAction } from 'redux-actions';

const MODULE = 'app/admin/allocations';

const CREATE_ALLOCATION_REQUEST = `${MODULE}/CREATE_ALLOCATION_REQUEST`;
const CREATE_ALLOCATION_RESPONSE = `${MODULE}/CREATE_ALLOCATION_RESPONSE`;
const CREATE_ALLOTMENTS_REQUEST = `${MODULE}/CREATE_ALLOTMENTS_REQUEST`;
const CREATE_ALLOTMENTS_RESPONSE = `${MODULE}/CREATE_ALLOTMENTS_RESPONSE`;
const UPDATE_ALLOCATION_REQUEST = `${MODULE}/UPDATE_ALLOCATION_REQUEST`;
const UPDATE_ALLOCATION_RESPONSE = `${MODULE}/UPDATE_ALLOCATION_RESPONSE`;
const UPDATE_ALLOTMENTS_REQUEST = `${MODULE}/UPDATE_ALLOTMENTS_REQUEST`;
const UPDATE_ALLOTMENTS_RESPONSE = `${MODULE}/UPDATE_ALLOTMENTS_RESPONSE`;
const DELETE_ALLOCATION_REQUEST = `${MODULE}/DELETE_ALLOCATION_REQUEST`;
const DELETE_ALLOCATION_RESPONSE = `${MODULE}/DELETE_ALLOCATION_RESPONSE`;
const GET_STATUSES_REQUEST = `${MODULE}/GET_STATUSES_REQUEST`;
const GET_STATUSES_RESPONSE = `${MODULE}/GET_STATUSES_RESPONSE`;
const FETCH_ALLOCATIONS_REQUEST = `${MODULE}/FETCH_ALLOCATIONS_REQUEST`;
const FETCH_ALLOCATIONS_RESPONSE = `${MODULE}/FETCH_ALLOCATIONS_RESPONSE`;

export const createAllocationRequest = createAction(CREATE_ALLOCATION_REQUEST);
export const createAllocationResponse = createAction(CREATE_ALLOCATION_RESPONSE);
export const createAllotmentsRequest = createAction(CREATE_ALLOTMENTS_REQUEST);
export const createAllotmentsResponse = createAction(CREATE_ALLOTMENTS_RESPONSE);
export const updateAllocationRequest = createAction(UPDATE_ALLOCATION_REQUEST);
export const updateAllocationResponse = createAction(UPDATE_ALLOCATION_RESPONSE);
export const updateAllotmentsRequest = createAction(UPDATE_ALLOTMENTS_REQUEST);
export const updateAllotmentsResponse = createAction(UPDATE_ALLOTMENTS_RESPONSE);
export const deleteAllocationRequest = createAction(DELETE_ALLOCATION_REQUEST);
export const deleteAllocationResponse = createAction(DELETE_ALLOCATION_RESPONSE);
export const getAllocationStatusesRequest = createAction(GET_STATUSES_REQUEST);
export const getAllocationStatusesResponse = createAction(GET_STATUSES_RESPONSE);
export const fetchAllAllocationsRequest = createAction(FETCH_ALLOCATIONS_REQUEST);
export const fetchAllAllocationsResponse = createAction(FETCH_ALLOCATIONS_RESPONSE);
