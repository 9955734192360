import cx from 'classnames';
import moment from 'moment';
import { string } from 'prop-types';
import React from 'react';

const DEFAULT_MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

/*
Very quick and dirty months dropdown,
generating `value={01}` for integer based months

*Requires withFormik() HOC wrapper*
*/
const MonthsDropdown = ({
  className,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  isFetching,
  isSubmitting,
  months,
  name,
  format
}) => {
  return (
    <select
      className={cx('form-control', className, {
        'is-invalid': touched[name] && errors[name]
      })}
      disabled={isFetching || isSubmitting}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder="Month"
      value={values[name]}
    >
      <option disabled value="">
        Select A Month
      </option>
      {months.map(m => {
        const formatNumber = m >= 10 ? m : `0${m}`;
        return (
          <option key={moment(m, 'MM')} value={m}>
            {formatNumber} - {moment(m, 'MM').format('MMMM')}
          </option>
        );
      })}
    </select>
  );
};

MonthsDropdown.propTypes = {
  name: string.isRequired
};

MonthsDropdown.defaultProps = {
  months: DEFAULT_MONTHS
};

export default MonthsDropdown;
