import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { compose } from 'recompose';

import { withInvoiceContext } from './InvoicesContext';
import InvoicesLayout from './InvoicesLayout';

class InvoicesIndex extends Component {
  static displayName = 'InvoicesIndexView';

  componentDidUpdate(prevProps) {
    const {
      month,
      year,
      history: { push },
      newBilling,
      billing
    } = this.props;

    if (prevProps.newBilling !== newBilling && newBilling.id) {
      push(`/admin/invoices/${month}/${year}/${newBilling.id}`);
    }

    if (prevProps.billing !== billing && billing.length > 0) {
      push(`/admin/invoices/${month}/${year}/${billing[0].id}`);
    }
  }

  render() {
    const { billing } = this.props;

    if (billing && billing.length === 0) {
      return (
        <Card>
          <CardBody>
            <div className="p-4">
              <p className="mb-0">Please create a new batch using the actions to the left.</p>
            </div>
          </CardBody>
        </Card>
      );
    }

    return null;
  }
}

const mapStateToProps = state => ({
  newBilling: state.invoices.newBilling
});

const ConnectedView = compose(withRouter, withInvoiceContext, connect(mapStateToProps))(InvoicesIndex);

export const InvoicesIndexView = ({ error }) => (
  <InvoicesLayout error={error}>
    <ConnectedView />
  </InvoicesLayout>
);

export default InvoicesIndexView;
