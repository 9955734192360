import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default class InvoicesCreateRemainingModal extends Component {
  render() {
    const { isOpen, onCancel, onSubmit, remainingCustomersCount } = this.props;
    return (
      <Modal isOpen={isOpen} keyboard>
        <ModalHeader>Create Remaining Subscriber Statements</ModalHeader>
        <ModalBody>
          <p>
            Subscribers selected through this action cannot be included in future batches created for this time period.
          </p>
          <p>
            <strong>{remainingCustomersCount} Subscribers</strong> will be included in this action.
          </p>
          <p>Do you wish to continue?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={onCancel}>
            No, Cancel
          </Button>
          <Button color="info" onClick={onSubmit}>
            Yes, Continue
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
