import download from 'downloadjs';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import moment from 'moment';

import Icon from 'shared/components/Icon';
import TruncatedText from 'shared/components/TruncatedText';

const StatusIcon = ({ status }) => {
  if (status === false) {
    return <Icon icon="highlight_off" style={{ color: 'red' }} />;
  }

  if (status === true) {
    return <Icon icon="check" style={{ color: 'green' }} />;
  }

  return null;
};

class InvoiceResultsTableRow extends Component {
  async handleInvoiceDownload() {
    const { month, year, displayName, invoiceNumber, invoiceId } = this.props;
    const monthName = moment.months(month - 1);
    const { payload } = await this.props.fetchInvoice(invoiceId);
    const filename = `${monthName} ${year} Statement #${invoiceNumber} - ${displayName}.pdf`;

    download(payload, filename, 'application/pdf');
  }

  renderDownloadLink() {
    return (
      <a
        href="/"
        onClick={e => {
          e.preventDefault();
          this.handleInvoiceDownload();
        }}
      >
        <Icon icon="get_app" />
      </a>
    );
  }

  deleteCustomerFromBilling = () => {
    const { deleteCustomer, billingCustomerDetailId } = this.props;
    deleteCustomer(billingCustomerDetailId);
  };

  goToBilling = () => {
    const {
      month,
      year,
      history: { push },
      sameBillingPeriodBillingId
    } = this.props;

    push(`/admin/invoices/${month}/${year}/${sameBillingPeriodBillingId}`);
  };

  renderContents() {
    const {
      isInvoiceGenerated,
      invoiceId,
      status,
      isInvoiceEnabled,
      isLedgered: isJournaled,
      isNotified,
      sameBillingPeriodBillingId
    } = this.props;

    const completedStatus = () => {
      if (invoiceId) return true;
      if (status === 30) return false;
    };

    if (sameBillingPeriodBillingId) {
      return (
        <>
          <td className="col-8 justify-content-start">
            <Button className="text-capitalize" color="link" onClick={this.goToBilling} size="sm">
              <Icon icon="subdirectory_arrow_left" /> view in batch
            </Button>
          </td>
          <td className="col-8 justify-content-end">
            {!invoiceId && (
              <div onClick={this.deleteCustomerFromBilling}>
                <Icon className="trash-can" icon="delete" />
              </div>
            )}
          </td>
        </>
      );
    }

    return (
      <>
        <td className="col-2 justify-content-center">
          <StatusIcon status={completedStatus()} />
        </td>

        <td className="col-2 justify-content-center">
          <StatusIcon status={isJournaled} />
        </td>

        <td className="col-2 justify-content-center">
          <StatusIcon status={isInvoiceEnabled} />
        </td>

        <td className="col-2 justify-content-center">
          <StatusIcon status={isNotified} />
        </td>

        <td className="col-1 justify-content-end">
          {isInvoiceGenerated && this.renderDownloadLink()}
          {!invoiceId && (
            <div onClick={this.deleteCustomerFromBilling}>
              <Icon className="trash-can" icon="delete" />
            </div>
          )}
        </td>
      </>
    );
  }

  render() {
    const { displayName, customerId } = this.props;

    return (
      <tr>
        <td className="col-3">
          <TruncatedText>
            <Link to={`/admin/subscribers/${customerId}`}>{displayName}</Link>
          </TruncatedText>
        </td>
        {this.renderContents()}
      </tr>
    );
  }
}

export default withRouter(InvoiceResultsTableRow);
